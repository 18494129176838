import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import {
  Geolocation,
  GeolocationOptions,
  Geoposition,
  PositionError,
} from "@ionic-native/geolocation/ngx";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { ApisService } from "src/app/services/apis.service";
import { Platform, ModalController, NavController } from "@ionic/angular";
import { UtilService } from "src/app/services/util.service";
import moment from "moment";
import { orderBy, uniqBy } from "lodash";
import Swal from "sweetalert2";
import { ChooseAddressPage } from "../choose-address/choose-address.page";
declare var google;

// import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


interface Product {
  id: string;
  name: string;
  price: number;
  cover: string;
  status: string;
  [key: string]: any; // Para permitir otras propiedades dinámicas
}


@Component({
  selector: "app-restaurants",
  templateUrl: "./restaurants.page.html",
  styleUrls: ["./restaurants.page.scss"],
})
export class RestaurantsPage implements OnInit {
  plt;
  allRest: any[] = [];
  closedRest: any[] = [];
  dummyClosed: any[] = [];
  venuesOut: any[] = [];
  dummyOut: any[] = [];
  headerHidden: boolean;
  chips: any[] = [];
  showFilter: boolean;
  lat: any;
  lng: any;
  address: any;
  dummyRest: any[] = [];
  dummy = Array(50);
  haveLocation: boolean;
  nearme: boolean = false;
  profile: any;
  banners: any[] = [];
  slideOpts = {
    slidesPerView: 1,
  };
  coupon: any;
  city: any;
  addressName: any;
  addressId: any;
  type: any;
  subcategories: any[];
  currentSubcategory: any;
  tacc: boolean = false;
  venueTypes: any[] = [];
  venueType: any;
  cart: any[] = [];
  totalItem: any = 0;

  fakeUrl: 'https://www.telam.com.ar/thumbs/bluesteel/advf/imagenes/2021/02/6022a9f78a0d6_900.jpg';
  isToggled = false;
  temporaryState = '';

  searchTerm: string = '';
  private searchSubject: Subject<string> = new Subject<string>();


  constructor(
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private diagnostic: Diagnostic,
    public geolocation: Geolocation,
    private router: Router,
    private api: ApisService,
    private util: UtilService,
    private apis: ApisService,
    public modalController: ModalController,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {
    const currentLng = this.util.getLanguage();
    // console.log("current language --->", currentLng);
    
    // ['Ratting 4.0+', 'Fastest Delivery', 'Cost'];
    this.haveLocation = false;
    if (this.platform.is("ios")) {
      this.plt = "ios";
    } else {
      this.plt = "android";
    }
    /*this.api.getBanners().then(data => {
      // console.log(data);
      this.banners = data;
    }).catch(error => {
      // console.log(error);
    });*/

    const city = JSON.parse(localStorage.getItem("deliveryAddress"));
    // console.log(city);
    if (city) {
      this.city = city;
    }
    const diet_type = localStorage.getItem('diet_type')
    console.log('diet_type', diet_type);
    if (diet_type == 'vegan') {
      this.isToggled = true
    }else{
      this.isToggled = false
    }
    
    this.route.queryParams.subscribe((data) => {
      // console.log(data);
      if (data && data.type) {
        // console.log("esta es la type", data.type);
        this.venueType = data.type
        this.api
          .getVenueTypes()
          .then((info) => {
            // console.log("venueTypes", info);
            this.venueTypes = info;
            let dummy = info.filter(element => element.name == data.type)
            // console.log(dummy);

            if (!dummy.length) {
              this.api
                .getBanners()
                .then((banner_info) => {
                  // console.log("banner_info:",banner_info);
                  this.banners = banner_info.filter((banner) => banner.type === data.type && banner.status === "active");


                  // console.log("sections:",this.banners);
                })
                .catch((error) => {
                  // console.log(error);
                });
            }
          })
          .catch((error) => {
            // console.log(error);
          });
        this.type = data.type;
        this.api
          .getSubCategories(this.type)
          .then((data) => {
            // console.log("antes del sort", data);

            data.sort((a, b) => {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              if (a.order == b.order) return 0;
            });


            // console.log("desps del sort",data);
            this.subcategories = data;
          })
          .catch((error) => {
            // console.log(error);
          });
        /*this.util.getAddress().subscribe(data => {
          // console.log(data);
        });*/
        this.getAddressMy();
        this.getRest();
      }
      if (data && data.coupon) {
        // console.log("esta es la type", data.coupon);
        this.coupon = data.coupon
        this.api
          .getVenueTypes()
          .then((info) => {
            // console.log("venueTypes", info);
            this.venueTypes = info;
            let dummy = info.filter(element => element.name == data.type)
            // console.log(dummy);

            if (!dummy.length) {
              this.api
                .getBanners()
                .then((banner_info) => {
                  // console.log("banner_info:",banner_info);
                  this.banners = banner_info.filter((banner) => banner.coupon === data.coupon && banner.status === "active");


                  // console.log("sections:",this.banners);
                })
                .catch((error) => {
                  // console.log(error);
                });
            }
          })
          .catch((error) => {
            // console.log(error);
          });
        this.type = data.type;

        this.getAddressMy();
        this.getRest();
      }
    });

    this.getAddressMy();
  }
  toggle() {
    console.log('Toggle clicked. Current state:', this.isToggled);
    console.log("Si el toggle es true: Estas en modo Vegan")
    this.isToggled = !this.isToggled;
    console.log("Ahora el toggle es", this.isToggled)
    this.temporaryState = this.isToggled ? 'toggle-temporary-1' : 'toggle-temporary-2';
    console.log('New temporary state:', this.temporaryState);
    this.getRest()
    if(this.isToggled){
      this.util.notificationSuccess(`Se activo el modo Vegan`)
      localStorage.setItem('diet_type', 'vegan')
    }else{
      this.util.notificationSuccess(`Se desactivo el modo Vegan`)
      localStorage.setItem('diet_type', 'vegetarian')

    }
    setTimeout(() => {
      this.temporaryState = ''; 
      console.log('Temporary state reset.');
    }, 2000);
  }

  ngOnInit() {
    // console.log("init");
    //this.init();
    this.route.queryParams.subscribe(params => {
      const filter = params['filter'];
      if (filter) {
        this.currentSubcategory = filter;
        this.applyFilter();
      } else {
        // Reset to default or handle no filter case
        this.currentSubcategory = '';
        this.allRest = this.dummyRest;
        this.venuesOut = this.dummyOut;
        this.closedRest = this.dummyClosed;
      }
    });

    this.searchSubject.pipe(
      debounceTime(300) // Esperar 300ms después de la última pulsación de tecla
    ).subscribe(value => {
      this.search(value);
    });

  }
  // estimateDelivery(){
  //   let cabifyId = localStorage.getItem("cabifyId")
  //   let info = {
  //     "parcel_ids": [
  //         cabifyId
  //     ]
  //   }
  //   this.api.estimateDelivery({body: info})
  //     .subscribe((data) => {
  //      // console.log("la vuelta del costo de envio cabify", data);



  //     }, error => {
  //       console.error("error cabify", error)
  //     });
  // }

  applyFilter() {
    if (this.currentSubcategory) {
      this.allRest = this.dummyRest.filter(ele => ele.cusine.includes(this.currentSubcategory));
      this.venuesOut = this.dummyOut.filter(ele => ele.cusine.includes(this.currentSubcategory));
      this.closedRest = this.dummyClosed.filter(ele => ele.cusine.includes(this.currentSubcategory));
    }
  }

  ionViewWillEnter() {
    // console.log("VIEWENTER!");
    // this.dummy = Array(10);
   
    this.getLocation();
    this.getProfile();
    const cart = localStorage.getItem("userCart");
    try {
      if (
        cart &&
        cart !== "null" &&
        cart !== undefined &&
        cart !== "undefined"
      ) {
        this.cart = JSON.parse(localStorage.getItem("userCart"));
        this.calculate();
      } else {
        this.cart = [];
      }
    } catch (error) {
      // console.log(error);
      this.cart = [];
    }

    // console.log("========================>", this.cart);
    // console.log("total item", this.totalItem);
  }

  async calculate() {
    // new
    let item = this.cart.filter((x) => x.quantiy > 0);
    this.cart.forEach((element) => {
      if (element.quantiy === 0) {
        element.selectedItem = [];
      }
    });
    // console.log("item=====>>", item);
    this.totalItem = 0;
    this.cart = [];
    // console.log("cart emplth", this.cart, item);
    item.forEach((element) => {
      this.totalItem = this.totalItem + element.quantiy;
      // console.log("itemsss----->>>", element);
      this.cart.push(element);
    });
    // console.log("carrrrrrr---->>>", this.cart);
    localStorage.setItem("userCart", JSON.stringify(this.cart));

    // console.log("total item", this.totalItem);
  }

  getAddressMy(newAddress = null) {
    const add = JSON.parse(localStorage.getItem("deliveryAddress"));
    if (add && add.address) {
      this.address = add.address;
      this.lat = add.lat;
      this.lng = add.lng;
      this.addressName = add.address.split(",")[0];
      this.addressId = add.id;
      this.haveLocation = true;
      this.city = add.city
      return this.address;
    } else if (newAddress) {
      //localStorage.setItem('deliveryAddress', JSON.stringify(newAddress));
      return newAddress;
    }
    return this.address;
  }
  taccChange() {
    // console.log("ahora el tacc es", this. tacc)
    if (!this.tacc) {
      this.currentSubcategory = "";
      this.allRest = this.dummyRest;
      this.closedRest = this.dummyClosed;
      this.venuesOut = this.dummyOut;
    } else {
      this.allRest = this.dummyRest.filter((ele: any) => {
        return ele.gluten_free
      });
      this.venuesOut = this.dummyOut.filter((ele: any) => {
        return ele.gluten_free
      });
      this.closedRest = this.dummyClosed.filter((ele: any) => {
        return ele.gluten_free
      });
    }

    FirebaseAnalytics.logEvent({
      name: 'evInteraction',
      params: {
        event_category: 'swipe_button',
        event_action: this.tacc ? 'on' : 'off',
        event_label: 'opciones_sin_gluten',
        value: 'no_aplica',
        section: 'restaurants'
      }
    }).then(() => {
      console.log("Logged event: evInteraction");
    }).catch((error) => {
      console.error("Error logging event:", error);
    });
  }
  getLocation() {
    this.platform.ready().then(() => {
      if (!localStorage.getItem("deliveryAddress")) {

        if (this.platform.is("android")) {
          this.androidPermissions
            .checkPermission(
              this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
            )
            .then(
              (result) => // console.log("Has permission?", result.hasPermission),
                (err) =>
                  this.androidPermissions.requestPermission(
                    this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
                  )
            );
          this.grantRequest();
        } else if (this.platform.is("ios")) {
          this.grantRequest();
        } else {
          this.geolocation
            .getCurrentPosition({
              maximumAge: 3000,
              timeout: 10000,
              enableHighAccuracy: false,
            })
            .then((resp) => {
              if (resp) {
                // console.log("resp", resp);
                /*this.lat = resp.coords.latitude;
              this.lng = resp.coords.longitude;*/
                this.getAddress(resp.coords.latitude, resp.coords.longitude);
              }
            })
            .catch((error) => {
              // console.log(error);
              this.grantRequest();
            });
        }
      }

    });
  }

  grantRequest() {
    this.diagnostic
      .isLocationEnabled()
      .then(
        (data) => {
          if (data) {
            this.geolocation
              .getCurrentPosition({
                maximumAge: 3000,
                timeout: 10000,
                enableHighAccuracy: false,
              })
              .then((resp) => {
                if (resp) {
                  // console.log("resp", resp);
                  /*this.lat = resp.coords.latitude;
            this.lng = resp.coords.longitude;*/
                  this.getAddress(resp.coords.latitude, resp.coords.longitude);
                }
              })
              .catch((error) => {
                // console.log(error);
              });
          } else {
            this.diagnostic.switchToLocationSettings();
            this.geolocation
              .getCurrentPosition({
                maximumAge: 3000,
                timeout: 10000,
                enableHighAccuracy: false,
              })
              .then((resp) => {
                if (resp) {
                  // console.log("ress,", resp);
                  /*this.lat = resp.coords.latitude;
            this.lng = resp.coords.longitude;*/
                  this.getAddress(resp.coords.latitude, resp.coords.longitude);
                }
              })
              .catch((error) => {
                // console.log(error);
              });
          }
        },
        (error) => {
          // console.log("errir", error);
          //this.dummy = [];
        }
      )
      .catch((error) => {
        // console.log("error", error);
        //this.dummy = [];
      });
  }

  getAddress(lat, lng) {
    const deliveryAddress = JSON.parse(localStorage.getItem("deliveryAddress"));
    if (!deliveryAddress) {
      setTimeout(() => {
        //this.haveLocation = true;
        const geocoder = new google.maps.Geocoder();
        const location = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: location }, (results, status) => {
          // console.log(results);
          // console.log("status", status);
          if (results && results.length) {
            this.address = results[0].formatted_address;
            this.lat = lat;
            this.lng = lng;
            this.addressName = this.address.split(",")[0];
            this.haveLocation = true;
            localStorage.setItem("myLat", this.lat);
            localStorage.setItem("myLng", this.lng);
            this.getRest();
            /*const address = {
              address: this.address,
              lat: this.lat,
              lng: this.lng,
              id: ''
            };*/
            //localStorage.setItem('deliveryAddress', JSON.stringify(address));
          } else {
            //this.dummy = [];
            this.util.errorToast("Something went wrong please try again later");
          }
        });
      }, 1000);
    } else {
      this.getAddressMy();
      this.getRest();
    }
  }

  degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }
  distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    // console.log(lat1, lon1, lat2, lon2);
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusKm * c;
    return Math.round(distance * 100) / 100;
  }

  getTime(time) {
    return moment(time).format("mm");
  }
  info_vegan() {


  }
  async presentModal() {
    /*const modal = await this.modalController.create({
      component: ChooseAddressPage
    });*/
    //return await modal.present();
    //await this.router.navigate(['choose-address']);
  }
  addParcel(venue) {

  }

  nearMe() {
    //this.dummy = Array(50);
    this.allRest = [];
    if (this.nearme) {
      this.dummyRest.forEach(async (element) => {
        const distance = await this.distanceInKmBetweenEarthCoordinates(
          this.lat,
          this.lng,
          element.lat,
          element.lng
        );
        // console.log("distance", distance);
        // Distance
        if (distance < 10) {
          this.allRest.push(element);
        }
      });
      //this.dummy = [];
    } else {
      this.allRest = this.dummyRest;
      //this.dummy = [];
    }
  }

  async estimateDeliveries() {
    let deliveries = {
      "requester_id": "738c0e2d02a011eda036c2c4b8b7367c",
      "parcels": [],
      "optimize": true,
      "departure_time": "2023-09-19T17:56:57.585Z"
    }
    await this.allRest.forEach(element => {

      let info = {

        "external_id": Math.random().toString(),
        "pickup_location": {
          "lat": element.lat,
          "lon": element.lng
        },
        "dropoff_location": {
          "lat": this.lat,
          "lon": this.lng
        },
        "dimensions": {
          "height": 2,
          "length": 2,
          "width": 2,
          "unit": "cm"
        },
        "weight": {
          "value": 1,
          "unit": "g"
        }
      }


      deliveries.parcels.push(info)
    });
    await (await this.api.estimateDeliveries({ body: deliveries })).subscribe(
      (data) => {
        // console.log("las estimaciones de los envios de cabify", data);
      })

  }
  
  change_url(input_url) {
    // Define the part to be replaced and the replacement
    const partToReplace = 'https://firebasestorage.googleapis.com/v0/b/v-go-app.appspot.com/o/';
    const replacement = 'http://34.117.68.250/';

    // Perform the replacement
    const modified_url = input_url.replace(partToReplace, replacement);

    // Return the modified URL
    return modified_url;
  }

  getRest() {
    this.dummy = Array(10);
    this.allRest = []
    if (!this.coupon) {
      let diet_type = this.isToggled ? 'vegan' : 'veggie'
    // Process venues for each type
        this.api.getVenues({
            closeBy:true,
            type: this.type,
            lng: this.lng,
            lat: this.lat,
            cityId: this.city,
            diet_type : diet_type
        })
        .subscribe(
          (data) => {
            // console.log("getRest", data);
            if (data && data.venuesOut) {
              this.venuesOut = data.venuesOut
              this.dummyOut = data.venuesOut
              // console.log("VENUES OUT",this.venuesOut);
            }
            if (data && data.venues && data.venues.length) {
              this.allRest = data.venues;
              this.dummyRest = data.venues;
              // this.venuesOut = data.venuesOut

              this.cd.detectChanges();
              /*data.venues.forEach((element) => {
            /*if (element && element.isClose === false && element.city === this.cityId) {
              element.time = moment(element.time).format('HH');
              const distance = this.distanceInKmBetweenEarthCoordinates(this.lat, this.lng, element.lat, element.lng);
              // console.log(distance)
              element.distance = distance;
              element.estimatedDeliveryTime = distance ? this.getEstimatedDeliveryTime(distance) : (element.time + ' minutos'); 
              this.allRest.push(element);
              this.dummyRest.push(element);
            }*/
              /*this.allRest.push(element);
            this.dummyRest.push(element);
          });*/
              //this.allRest.sort((a,b) => a.distance - b.distance)
              this.dummy = [];
            } else {
              this.allRest = [];
              this.dummy = [];
            }
            if (data && data.venuesClosed && data.venuesClosed.length) {
              let diaHoy = new Date().getDay();
              if ((diaHoy == 0)) {
                diaHoy = 6;
              } else if (diaHoy == 1) {
                diaHoy = 0;
              }
              else if (diaHoy == 2) {
                diaHoy = 1;
              }
              else if (diaHoy == 3) {
                diaHoy = 2;
              }
              else if (diaHoy == 4) {
                diaHoy = 3;
              }
              else if (diaHoy == 5) {
                diaHoy = 4;
              }
              else if (diaHoy == 6) {
                diaHoy = 5;
              }

              data.venuesClosed.forEach((element) => {


                let status = false
                if (element.status == 'open') {
                  status = true
                }

                let isClose = false
                if (element.isClose) {
                  isClose = true
                }

                let current = false
                if (status && !isClose) {
                  current = true
                }

                if (!current) {
                  element.scheduleToday = "Cerrado";
                } else {
                  if (element.schedule) {
                    const scheduleToday = element.schedule[diaHoy];
                    const scheduleToday0 =
                      scheduleToday[0].open + " - " + scheduleToday[0].close;
                    const scheduleToday1 =
                      scheduleToday[1].open + " - " + scheduleToday[1].close;

                    if (
                      scheduleToday[0].open != "" &&
                      scheduleToday[1].open != ""
                    ) {
                      element.scheduleToday =
                        scheduleToday0 + " y " + scheduleToday1;
                    } else if (scheduleToday[0].open != "") {
                      element.scheduleToday = scheduleToday0;
                    } else if (scheduleToday[1].open != "") {
                      element.scheduleToday = scheduleToday1;
                    } else {
                      element.scheduleToday = "Hoy no abre";
                    }
                  } else {
                    element.scheduleToday = "Hoy no abre";
                  }
                }
              });

              data.venues.forEach((element) => {

                if (element.schedule) {
                  const scheduleToday = element.schedule[diaHoy];
                  const scheduleToday0 =
                    scheduleToday[0].open + " - " + scheduleToday[0].close;
                  const scheduleToday1 =
                    scheduleToday[1].open + " - " + scheduleToday[1].close;

                  if (
                    scheduleToday[0].open != "" &&
                    scheduleToday[1].open != ""
                  ) {
                    element.scheduleToday =
                      scheduleToday0 + " y " + scheduleToday1;
                  } else if (scheduleToday[0].open != "") {
                    element.scheduleToday = scheduleToday0;
                  } else if (scheduleToday[1].open != "") {
                    element.scheduleToday = scheduleToday1;
                  } else {
                    element.scheduleToday = "Hoy no abre";
                  }
                } else {
                  element.scheduleToday = "Hoy no abre";
                }
              });
              // this.estimateDeliveries()
              this.closedRest = data.venuesClosed;
              this.dummyClosed = data.venuesClosed;

            } else {
              this.closedRest = [];
            }
            this.dummy = [];
          },
          (error) => {
            // console.log(error);
            this.dummy = [];
          }
        );
    } else {
      this.api
        .getVenuesByCoupon({
          offer_id: this.coupon,
          lng: this.lng,
          lat: this.lat,
          cityId: this.city,
        })
        .subscribe(
          (data) => {
            // console.log("getRest", data);
            if (data && data.venuesOut) {
              this.venuesOut = data.venuesOut
              this.dummyOut = data.venuesOut
              // console.log("VENUES OUT",this.venuesOut);
            }
            if (data && data.venues && data.venues.length) {
              this.allRest = data.venues;
              this.dummyRest = data.venues;
              // this.venuesOut = data.venuesOut

              this.cd.detectChanges();
              /*data.venues.forEach((element) => {
            /*if (element && element.isClose === false && element.city === this.cityId) {
              element.time = moment(element.time).format('HH');
              const distance = this.distanceInKmBetweenEarthCoordinates(this.lat, this.lng, element.lat, element.lng);
              // console.log(distance)
              element.distance = distance;
              element.estimatedDeliveryTime = distance ? this.getEstimatedDeliveryTime(distance) : (element.time + ' minutos'); 
              this.allRest.push(element);
              this.dummyRest.push(element);
            }*/
              /*this.allRest.push(element);
            this.dummyRest.push(element);
          });*/
              //this.allRest.sort((a,b) => a.distance - b.distance)
              this.dummy = [];
            } else {
              this.allRest = [];
              this.dummy = [];
            }
            if (data && data.venuesClosed && data.venuesClosed.length) {
              let diaHoy = new Date().getDay();
              if ((diaHoy == 0)) {
                diaHoy = 6;
              } else if (diaHoy == 1) {
                diaHoy = 0;
              }
              else if (diaHoy == 2) {
                diaHoy = 1;
              }
              else if (diaHoy == 3) {
                diaHoy = 2;
              }
              else if (diaHoy == 4) {
                diaHoy = 3;
              }
              else if (diaHoy == 5) {
                diaHoy = 4;
              }
              else if (diaHoy == 6) {
                diaHoy = 5;
              }

              data.venuesClosed.forEach((element) => {
                let status = false
                if (element.status == 'open') {
                  status = true
                }

                let isClose = false
                if (element.isClose) {
                  isClose = true
                }

                let current = false
                if (status && !isClose) {
                  current = true
                }

                if (!current) {
                  element.scheduleToday = "No disponible temporalmente";
                } else {
                  if (element.schedule) {
                    const scheduleToday = element.schedule[diaHoy];
                    const scheduleToday0 =
                      scheduleToday[0].open + " - " + scheduleToday[0].close;
                    const scheduleToday1 =
                      scheduleToday[1].open + " - " + scheduleToday[1].close;

                    if (
                      scheduleToday[0].open != "" &&
                      scheduleToday[1].open != ""
                    ) {
                      element.scheduleToday =
                        scheduleToday0 + " y " + scheduleToday1;
                    } else if (scheduleToday[0].open != "") {
                      element.scheduleToday = scheduleToday0;
                    } else if (scheduleToday[1].open != "") {
                      element.scheduleToday = scheduleToday1;
                    } else {
                      element.scheduleToday = "Hoy no abre";
                    }
                  } else {
                    element.scheduleToday = "Hoy no abre";
                  }
                }
              });

              data.venues.forEach((element) => {
                if (element.schedule) {
                  const scheduleToday = element.schedule[diaHoy];
                  const scheduleToday0 =
                    scheduleToday[0].open + " - " + scheduleToday[0].close;
                  const scheduleToday1 =
                    scheduleToday[1].open + " - " + scheduleToday[1].close;

                  if (
                    scheduleToday[0].open != "" &&
                    scheduleToday[1].open != ""
                  ) {
                    element.scheduleToday =
                      scheduleToday0 + " y " + scheduleToday1;
                  } else if (scheduleToday[0].open != "") {
                    element.scheduleToday = scheduleToday0;
                  } else if (scheduleToday[1].open != "") {
                    element.scheduleToday = scheduleToday1;
                  } else {
                    element.scheduleToday = "Hoy no abre";
                  }
                } else {
                  element.scheduleToday = "Hoy no abre";
                }
              });
              // this.estimateDeliveries()
              this.closedRest = data.venuesClosed;
              this.dummyClosed = data.venuesClosed;
            } else {
              this.closedRest = [];
            }
            this.dummy = [];
          },
          (error) => {
            // console.log(error);
            this.dummy = [];
          }
        );
    }

  }

  addFilter(index) {
    // console.log(index);
    if (index === 0) {
      // console.log("rating");
      this.allRest = orderBy(this.allRest, "ratting", "desc");
    } else if (index === 1) {
      // console.log("fast");
      this.allRest = orderBy(this.allRest, "time", "asc");
    } else {
      // console.log("cost");
      this.allRest = orderBy(this.allRest, "dishPrice", "asc");
    }
    this.allRest = uniqBy(this.allRest, "id");
  }

  addFilterCategory(subcategory) {
    // console.log("subcategoryyyy", subcategory, this.allRest);
    if (this.currentSubcategory == subcategory) {
      this.currentSubcategory = "";
      this.allRest = this.dummyRest;
      this.closedRest = this.dummyClosed;
      this.venuesOut = this.dummyOut;
    } else {
      this.currentSubcategory = subcategory;
      this.allRest = this.dummyRest.filter((ele: any) => {
        return ele.cusine.includes(subcategory.name);
      });
      this.venuesOut = this.dummyOut.filter((ele: any) => {
        return ele.cusine.includes(subcategory.name);
      });
      this.closedRest = this.dummyClosed.filter((ele: any) => {
        return ele.cusine.includes(subcategory.name);
      });

      FirebaseAnalytics.logEvent({
        name: 'evInteraction',
        params: {
          event_category: 'button',
          event_action: 'click',
          event_label: subcategory.name,
          value: 'no_aplica',
          section: 'restaurants'
        }
      }).then(() => {
        console.log("Logged event: evInteraction");
      }).catch((error) => {
        console.error("Error logging event:", error);
      });

    }
  }

  openMenu(item) {
    if (item && item.status === "close") {
      return false;
    }
    // this.currentSubcategory = false

    //   //if(!item.isDeliveryRange) return false
    //   let delivery = JSON.parse(localStorage.getItem("deliveryAddress"))
    //   // console.log("estes es el delivery address addre", delivery);

    //   var orderInfo = {
    //     "parcels": [
    //       {
    //         "pickup_info": {
    //           "addr": item.address,
    //           "contact": {
    //             "name": "nombre",
    //             "phone": "+5491151136011"
    //           },
    //           "instr": "Probando el costo de envio",
    //           "loc": {
    //             "lat": item.lat,
    //             "lon": item.lng
    //           }
    //         },
    //         "dropoff_info": {
    //           "addr": delivery.address,
    //           "contact": {
    //             "name": "Joaquin Gomez",
    //             "phone": "111111111"
    //           },
    //           "instr": "Probando el costo de envio",
    //           "loc": {
    //             "lat": delivery.lat,
    //             "lon": delivery.lng
    //           }
    //         },
    //         "dimensions": {
    //           "height": 2,
    //           "length": 2,
    //           "width": 2,
    //           "unit": "UNIT"
    //         },
    //         "weight": {
    //           "value": 3,
    //           "unit": "2"
    //         }
    //       }
    //     ]
    //   };
    //  // console.log("este es order info", orderInfo);
    //  this.api.addNewParcel({body: orderInfo})
    //  .subscribe((data) => {
    //    // console.log("la vuelta", data);

    //    if(data && data.parcels){
    //       localStorage.removeItem("cabifyId")

    //       localStorage.setItem("cabifyId", data.parcels[0].id);
    //       // console.log("deberia haber data y data.id", data);

    console.log('currentsubcategory', this.currentSubcategory)
    const navData: NavigationExtras = {
      queryParams: {
        id: item.uid,
        filter: this.currentSubcategory.name,
        searchTerm: this.searchTerm
      },
    };
    this.router.navigate(["category"], navData);
    //     }

    //     }, error => {
    //       this.api.cabifyError("addNewParcelRestaurants",error)
    //       console.error("error cabify", error)
    //     });
    //  // console.log("termino la funcion de addnewparcel");


  }

  openOffers(item) {
    const navData: NavigationExtras = {
      queryParams: {
        id: item.restId,
      },
    };
    this.router.navigate(["category"], navData);
  }

  onSearchChange(event) {
    // console.log(event.detail.value);
    const searchTerm = event.detail.value.toLowerCase();
    this.searchSubject.next(searchTerm);
    return

    // this.allRest = this.dummyRest.filter((ele: any) => {
    //   return ele.name.toLowerCase().includes(searchTerm) || ele.descriptions.toLowerCase().includes(searchTerm) || (ele.productString ? ele.productString.toLowerCase().includes(searchTerm) : false);
    // });

    // this.closedRest = this.dummyClosed.filter((ele: any) => {
    //   console.log(ele.name);
    //   return ele.name.toLowerCase().includes(searchTerm) || ele.descriptions.toLowerCase().includes(searchTerm) || (ele.productString ? ele.productString.toLowerCase().includes(searchTerm) : false);
    // });

    // this.venuesOut = this.dummyOut.filter((ele: any) => {
    //   return ele.name.toLowerCase().includes(searchTerm) || ele.descriptions.toLowerCase().includes(searchTerm) || (ele.productString ? ele.productString.toLowerCase().includes(searchTerm) : false);
    // });

  }

  
  parseConcatenatedInfo(concatenatedInfo: string): Product[] {
    return concatenatedInfo.split('||').map(productString => {
      const productAttributes = productString.split('%50').reduce((acc: { [key: string]: string }, attribute) => {
        const [key, ...valueParts] = attribute.split(':');
        const value = valueParts.join(':'); // Rejoin the remaining parts with ':'
        acc[key] = value;
        return acc;
      }, {}) as unknown as Product;
  
      // Log the product and its status for debugging
      try {
        // Attempt to parse the status
        if (JSON.parse(productAttributes.status) === false) {
        }
      } catch (error) {
      }
  
      return productAttributes;
    }).filter(product => {
      try {
        return JSON.parse(product.status) !== false;
      } catch (error) {
        // Exclude invalid status products
        return false;
      }
    });
  }

  search(searchTerm) {
    console.log(this.dummyRest)
    this.dummyRest.forEach((venue: any) => {
      delete venue.matchesProducts;
    });
    this.dummyClosed.forEach((venue: any) => {
      delete venue.matchesProducts;
    });
  
    if (!searchTerm || searchTerm == "") {
      this.allRest = this.dummyRest
      this.closedRest = this.dummyClosed
      return
    }
    // const searchTerm = event.detail.value.toLowerCase();

    this.allRest = []

    this.dummyRest.forEach((venue: any) => {
      const matchesVenue = venue.name.toLowerCase().includes(searchTerm) || venue.descriptions.toLowerCase().includes(searchTerm);
      if (venue.productString && venue.productString.length > 2) {
        const products = this.parseConcatenatedInfo(venue.productString);
        let matchesProducts = products.filter(product =>
          product.name.toLowerCase().includes(searchTerm)
        );
        
        // Remove duplicates by converting to a Map based on a unique property, e.g., `id`.
        matchesProducts = Array.from(new Map(matchesProducts.map(product => [product.id, product])).values());
        
        



        if (matchesProducts.length > 0) {
          venue.matchesProducts = [...matchesProducts].slice(0,4); // Crear una copia de matchesProducts
          // venue.matchesProducts = matchesProducts
          this.allRest.push(venue)
        } else if (matchesVenue) {
          this.allRest.push(venue)
        }
      } else if (matchesVenue) {
        this.allRest.push(venue)
      }

    });

    

    this.closedRest = []
    this.dummyClosed.forEach((venue: any) => {
      const matchesVenue = venue.name.toLowerCase().includes(searchTerm) || venue.descriptions.toLowerCase().includes(searchTerm);
      if (venue.productString && venue.productString.length > 2) {
        const products = this.parseConcatenatedInfo(venue.productString);
        let matchesProducts = products.filter(product =>
          product.name.toLowerCase().includes(searchTerm)
        );
        
        // Remove duplicates by converting to a Map based on a unique property, e.g., `id`.
        matchesProducts = Array.from(new Map(matchesProducts.map(product => [product.id, product])).values());
        

        if (matchesProducts.length > 0) {
          venue.matchesProducts = [...matchesProducts].slice(0,4); // Crear una copia de matchesProducts
          // venue.matchesProducts = matchesProducts
          this.closedRest.push(venue)
        } else if (matchesVenue) {
          this.closedRest.push(venue)
        }
      } else if (matchesVenue) {
        this.closedRest.push(venue)
      }

    });
    FirebaseAnalytics.logEvent({
      name: 'evInteraction',
      params: {
        event_category: 'text_input',
        event_action: 'fill',
        event_label: 'que_vas_a_pedir_hoy',
        value: searchTerm,
        section: 'restaurants'
      }
    }).then(() => {
      console.log("Logged event: evInteraction");
    }).catch((error) => {
      console.error("Error logging event:", error);
    });
  }

  getFormattedPrice(price) {
    let priceNumber = Number(price)
    const formattedPrice = priceNumber.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return formattedPrice
  }


  getCusine(cusine) {
    return cusine.join(" | ");
  }

  onScroll(event) {
    if (event.detail.deltaY > 0 && this.headerHidden) return;
    if (event.detail.deltaY < 0 && !this.headerHidden) return;
    if (event.detail.deltaY > 80) {
      this.headerHidden = true;
    } else {
      this.headerHidden = false;
    }
  }

  // searchProducts() {
  //   // console.log("ARRANCA BUSCAR PRODCUTOS");
  //   let param = 'pizza'
  //   this.api.searchProducts(param).then(data => {
  //     // console.log(data);
  //   })
  // }

  getProfile() {
    if (localStorage.getItem("uid")) {
      this.apis
        .getProfile(localStorage.getItem("uid"))
        .then(
          (data) => {
            // console.log(data);
            if (data && data.cover) {
              this.profile = data.cover;
            }
            if (data && data.status === "inactive") {
              localStorage.removeItem("uid");
              this.api.logout().then((data) => {
                // console.log(data);
              });
              this.router.navigate(["login"]);
              Swal.fire({
                title: "Error",
                text: "Your are blocked please contact administrator",
                icon: "error",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Need Help?",
                backdrop: false,
                background: "white",
              }).then((data) => {
                if (data && data.value) {
                  this.router.navigate(["inbox"]);
                }
              });
            }
          },
          (err) => {
            // console.log("Err", err);
          }
        )
        .catch((e) => {
          // console.log("Err", e);
        });
    }
  }

  chipChange(item) {
    this.allRest = this.dummyRest;
    // console.log(item);
    if (item === "Fastest Delivery") {
      this.allRest.sort((a, b) => {
        a = new Date(a.time);
        b = new Date(b.time);
        return a > b ? -1 : a < b ? 1 : 0;
      });
    }

    if (item === "Ratting 4.0+") {
      this.allRest = [];

      this.dummyRest.forEach((ele) => {
        if (ele.ratting >= 4) {
          this.allRest.push(ele);
        }
      });
    }

    if (item === "Cost") {
      this.allRest.sort((a, b) => {
        a = a.time;
        b = b.time;
        return a > b ? -1 : a < b ? 1 : 0;
      });
    }
  }

  changeLocation() {
    //this.navCtrl.navigateRoot(['cities']);
    if (localStorage.getItem("uid")) {
      this.router.navigate(["choose-address"], {
        queryParams: {
          from: "home",
        },
      });
    } else {
      this.router.navigate(["add-new-address"]);

    }
  }

  getCurrency() {
    return this.util.getCurrecySymbol();
  }

  getEstimatedDeliveryTime(distance) {
    if (distance < 3) {
      return "1 hora";
    } else if (distance >= 3 && distance < 6) {
      return "1 hora";
    } else if (distance >= 6 && distance < 9) {
      return "1 hora y media";
    } else {
      return "2 horas";
    }
  }

  goToCart() {

    this.router.navigate(["cart"], {});


  }

  goTo(nav) {
    this.router.navigate([nav]);
  }
  doRefresh(event) {
    // console.log('Begin async operation');
    this.tacc = false
    this.ionViewWillEnter();
    this.getRest();
    this.getAddressMy();

    setTimeout(() => {
      // console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

}
