import { Injectable } from '@angular/core';
import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import { Platform } from '@ionic/angular';
import firebase from 'firebase';
import { config } from './config.service';

const PACKAGING_PRICE = 'packaging_price'
const DELIVERY_PRICE = 'delivery_price'
const DELIVERY_TIME = 'delivery_time'
const DELIVERY_RANGE = 'delivery_radius_kms'

@Injectable({
  providedIn: 'root'
})
export class FirebaseConfigService {
  constructor(
    private firebaseConfig: FirebaseConfig,
    private platform: Platform
  ) {
  }

  getPackagingAmount() {
    if(this.platform.is('capacitor')) {
      return this.firebaseConfig.getNumber(PACKAGING_PRICE)
        .then((res: any) => Number(res))
        .catch((error: any) => console.error(error));
    } else {
      const remoteConfig = firebase.remoteConfig();
      const value = remoteConfig.getNumber(PACKAGING_PRICE);
      return new Promise((resolve, reject) => resolve(value || config[PACKAGING_PRICE]))
    }
  }

  getDeliveryPrice() {
    if(this.platform.is('capacitor')) {
      return this.firebaseConfig.getNumber(DELIVERY_PRICE)
        .then((res: any) => Number(res))
        .catch((error: any) => console.error(error));
    } else {
      const remoteConfig = firebase.remoteConfig();
      const value = remoteConfig.getNumber(DELIVERY_PRICE);
      return new Promise((resolve, reject) => resolve(value || config[DELIVERY_PRICE]))
    }
  }

  getDeliveryTime() {
    if(this.platform.is('capacitor')) {
      return this.firebaseConfig.getNumber(DELIVERY_TIME)
        .then((res: any) => Number(res))
        .catch((error: any) => console.error(error));
    } else {
      const remoteConfig = firebase.remoteConfig();
      const value = remoteConfig.getNumber(DELIVERY_TIME);
      return new Promise((resolve, reject) => resolve(value || config[DELIVERY_TIME]))
    }
  }

  getDeliveryRange() {
    if(this.platform.is('capacitor')) {
      return this.firebaseConfig.getNumber(DELIVERY_RANGE)
        .then((res: any) => Number(res))
        .catch((error: any) => console.error(error));
    } else {
      const remoteConfig = firebase.remoteConfig();
      const value = remoteConfig.getNumber(DELIVERY_RANGE);
      return new Promise((resolve, reject) => resolve(value || config[DELIVERY_RANGE]))
    }
  }

}