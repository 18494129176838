import { Component, OnInit } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import moment from 'moment';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.page.html',
  styleUrls: ['./coupons.page.scss'],
})
export class CouponsPage implements OnInit {
  list: any[] = [];
  restId: any;
  name: any;
  total: any;
  dummy = Array(10);
  fromCheckout: boolean;
  newCoupon: string;
  userId: any;
  constructor(
    private api: ApisService,
    private route: ActivatedRoute,
    private util: UtilService,
    private navCtrl: NavController,
    private router: Router,

  ) {
    this.util.show()

    this.userId = localStorage.getItem('uid')
    this.getOffers();

  }

  ngOnInit() {

    this.route.queryParams.subscribe(data => {
      // console.log(data);
      if (data && data.restId) {
        this.restId = data.restId;
        this.name = data.name;
        this.total = parseFloat(data.totalPrice);
        this.fromCheckout = data.fromCheckout;
        // console.log(this.restId);
      } else {
        this.dummy = [];
      }
    });
  }
  getOffers() {
    this.api.getOffers(this.userId).then(data => {
      this.dummy = [];
      // console.log('list=====>', data);
      this.list = [];
      if (data && data.length) {
        this.util.hide()
        const currnetDate = moment().format('YYYY-MM-DD');
        data.forEach(element => {
          // console.log(element)
          const offer = element.oid;
          // console.log(moment(offer.expire).isAfter(currnetDate));
          // if (offer && element.status === 'active' && offer.status === 'active' && moment(offer.expire).isAfter(currnetDate)) {
          //   offer.status = 'Activo'
          // }
          // else if (offer && element.status === 'active' && offer.status === 'active') {
            //   offer.status = 'Vencido'
            // }
            // else {
              //   offer.status = 'Inactivo'
              // }
            if (offer && element.status === 'active' && offer.status === 'active' && moment(offer.expire).isAfter(currnetDate)) {
              offer.status = 'Activo'
              // console.log('yes=>', offer);
              this.list.push(offer);
          }
        });
        // this.list = data;
      }
      this.util.hide()

    }).catch(error => {
      this.util.hide()

      this.dummy = [];
      // console.log(error);
    });
  }
  goToVenues(item){
    const navData: NavigationExtras = {
      queryParams: {
        coupon: item.id,
        from:'coupons'
      },
    };
    this.router.navigate(["restaurants"], navData);
  }

  async claim(item) {
    // console.log(item);
    
    if (item && (item.available && item.available.length || item.all_venues|| item.categories.length)) {
      const data = item.available.filter(x => x.id === this.restId);
      let venueInfo; 
      let categories;
      let isCategory;
      if(item.categories){
        categories = item.categories.map(element=> element.name)
        venueInfo = await this.api.getVenueDetails(this.restId)
        isCategory = venueInfo.types.some(element => {
          return categories.includes(element);
        });
      }
     
      if ((data && data.length) || item.all_venues || isCategory ) {
        if (this.total >= item.min) {
          // console.log('ok');
          if(item.firstOrder) {
          console.log("SOLO PARA PRIMERA COMPRA")

            const orders = await this.api.getMyOrders(this.userId);
            if(orders && orders.length && orders.some(order => order.status === 'delivered')) {
              this.util.errorToast('Este cupón es válido sólo para primera compra');
              return;
            }
          }
          this.util.publishCoupon(item);
          this.util.showToast(this.util.translate('Coupon Applied'), 'success', 'bottom');
          this.navCtrl.back();
          return;

        } else {
          this.util.errorToast(this.util.translate('For claiming this coupon your order required minimum order  of $') + item.min);
        }
      } else{
        this.util.errorToast(this.util.translate('This coupon is not valid for ') + this.name);
      }
    } else {
      this.util.errorToast(this.util.translate('This coupon is not valid for ') + this.name);
    }
  }
  expire(date) {
    return moment(date).format('llll');
  }

  getCurrency() {
    return this.util.getCurrecySymbol();
  }

  validateCoupon() {
    this.api.addCoupon(this.userId, {code: this.newCoupon}).then(data => {
      this.getOffers();
      this.newCoupon = '';
    }).catch(error => {
      this.dummy = [];
      // console.log(error);
      this.util.errorToast(error);

    });
  }
}
