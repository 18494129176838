import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApisService } from '../services/apis.service';
import { UtilService } from 'src/app/services/util.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    pagesWithoutPhone = ["/tabs/tab4"]

    constructor(
        private authServ: ApisService,
        private router: Router,
        private util: UtilService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        return this.authServ.checkAuth().then((user: any) => {
            if (user) {
                localStorage.setItem('uid', user.uid);
                localStorage.setItem('help', user.uid);

                if(this.pagesWithoutPhone.includes(state.url)) {
                    return true;
                }
                if((!user.profile || !user.profile.phone)) {
                    this.router.navigate(['edit-profile'], {
                        queryParams: {
                            referralUrl: state.url
                        }
                    });
                } else {
                    return true;
                }
            } else {
                this.util.errorToast(
                    this.util.translate("In order to continue we need you to log in.")
                );
                this.router.navigate(['login']);
            }
        }).catch(error => {
            console.log(error);
            this.util.errorToast(
                this.util.translate("In order to continue we need you to log in.")
              );
            this.router.navigate(['login']);
            
        });
    }
}
