import { VariationsPage } from "./../variations/variations.page";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import {
  NavController,
  AlertController,
  PopoverController,
  ModalController,
  Platform
} from "@ionic/angular";
import { ApisService } from "src/app/services/apis.service";
import { UtilService } from "src/app/services/util.service";
import { Router } from "@angular/router";
import { MenuComponent } from "src/app/components/menu/menu.component";
import { FirebaseConfigService } from "src/app/services/firebase-config.service";
import swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import OneSignal from 'onesignal-cordova-plugin';
import Swal from "sweetalert2";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: "app-category",
  templateUrl: "./category.page.html",
  styleUrls: ["./category.page.scss"],
})
export class CategoryPage implements OnInit {
  @ViewChild("content") private content: any;
  id: any;
  slideOpts = {
    slidesPerView: 2.3,
  };
  feedback_amount : any;
  cheapest_delivery: any;
  venue: any;
  name: any;
  isToggled = false;
  headerHidden: boolean;
  descriptions: any;
  cover: any = "";
  logo: any = "";
  final_delivery: any = "";
  address: any;
  ratting: any;
  rating: any;
  time: any;
  totalRatting: any;
  dishPrice: any;
  cusine: any[] = [];
  type: any[] = [];
  foods: any[] = [];
  dummyFoods: any[] = [];
  categories: any[] = [];
  featured: any[] = [];
  dummyFeatured: any[] = [];
  dummyCategories: any[] = [];
  dummy = Array(50);
  tacc: boolean = false;
  estimatedDeliveryTime: any = 0;
  estimatedDeliveryTime15: any = 0;
  totalItem: any = 0;
  totalPrice: any = 0;
  takeaway: boolean = false
  deliveryAddress: any = "";
  foodIds: any[] = [];
  cart: any[] = [];
  originalPrice: any;
  schedule: any;
  scheduleToday: any;
  isOpen: boolean = false;
  isNear: boolean = false;
  available: boolean = false;
  vegan_venue: boolean = false;
  vegan: boolean = false;
  veggie: boolean = false;
  cabifyError: boolean = false;
  takeaway_available: boolean = false;
  distance: any;
  deliveryCharge: any;
  lat: any;
  lng: any;
  estimatedPreparationTime: any;
  show_gluten: boolean = true
  rest: any;
  mp_commission: any;

  DELIVERY_RANGE: any = 50;

  DELIVERY_PRICE: any = 0;
  constructor(
    private route: ActivatedRoute,
    private api: ApisService,
    private util: UtilService,
    private navCtrl: NavController,
    private alertController: AlertController,
    private router: Router,
    private popoverController: PopoverController,
    private modalCtrl: ModalController,
    private cd: ChangeDetectorRef,
    private firebaseConfigService: FirebaseConfigService,
    private platform: Platform,


  ) { 

    if(this.platform.is("desktop")){
      this.slideOpts = {
        slidesPerView : 7
      }
    }
    const diet_type = localStorage.getItem('diet_type')
    console.log('diet_type', diet_type);
    if (diet_type == 'vegan') {
      this.isToggled = true
    }else{
      this.isToggled = false
    }
  }
  isCurrentTimeInTimeRange() {
    // Get the current date and time in Argentina Time (UTC-3)
    const currentDateTime = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" }));

    // Set the time range (9 AM to 11 PM)
    const startTime = new Date(currentDateTime);
    startTime.setHours(9, 0, 0, 0);

    const endTime = new Date(currentDateTime);
    endTime.setHours(23, 0, 0, 0);

    // console.log("Start Time in Argentina Time:", startTime.toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" }));
    // console.log("End Time in Argentina Time:", endTime.toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" }));
    // console.log("Current Time in Argentina Time:", currentDateTime.toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" }));

    return startTime <= currentDateTime && currentDateTime <= endTime;
  }
  // Function to format date in ISO 8601 format
  formatISODate(date) {
    return date.toISOString().slice(0, 19) + "Z";
  }
  change_takeaway(bool){
    if(this.takeaway_available){
      if(!bool){
        localStorage.setItem('takeaway', "false")
        this.takeaway = false
      }else{
        localStorage.setItem('takeaway', "true")
        this.takeaway = true
      }
    }
  }

  async peyaEstimate() {

    // Example usage

    // Get the current date and time
    const now = new Date();

    // Add 20 minutes to the current date and time
    const deliveryTime = new Date(now.getTime() + 20 * 60000); // 20 minutes in milliseconds

    // Format the delivery time in ISO 8601 format
    const formattedTime = this.formatISODate(deliveryTime);

    // console.log(formattedTime)
    let param = JSON.stringify({
      "referenceId": "Client Internal Reference",
      "deliveryTime": formattedTime,
      // "isTest": true,
      "notificationMail": "milton@vgo.com.ar",
      "items": [
        {
          "type": "STANDARD",
          "value": 1250.6,
          "description": "V-Go Envio.",
          "sku": "order_id",
          "quantity": 1,
          "volume": 10.01,
          "weight": 0.5
        }
      ],
      "waypoints": [
        {
          "type": "PICK_UP",
          "addressStreet": this.address,
          "addressAdditional": "Piso 6 Recepción",
          "city": "Buenos Aires",
          "latitude": this.lat,
          "longitude": this.lng,
          "phone": "+541125112209",
          "name": this.name,
          "instructions": "Probando el costo de envio."
        },
        {
          "type": "DROP_OFF",
          "latitude": this.deliveryAddress.lat,
          "longitude": this.deliveryAddress.lng,
          "addressStreet": this.deliveryAddress.address,
          "addressAdditional": "Piso 1, Oficina Delivery",
          "city": "Buenos Aires",
          "phone": "+5491125112209",
          "name": "Milton",
          "instructions": "Entregar en mano",
          "collectMoney": 125
        }
      ]
    });

    (await this.api.estimatePeya({ body: param })).subscribe(
      (data) => {
        // console.log("mando a estimar esto", JSON.parse(param))

        // console.log("la estimacion del enivo de peya", data);
        localStorage.removeItem("peyaId")
        if (data) {


          let dummyPrice = String(data.deliveryOffers[0].pricing.total)
          this.deliveryCharge = dummyPrice
          localStorage.setItem("peyaId", data.deliveryOffers[0].deliveryOfferId);

          // this.deliveryCharge = parseFloat(dummyPrice.substring(0, dummyPrice.length - 2) + "." + dummyPrice.substring(dummyPrice.length - 2))

          this.addParcelandestimateDelivery("peya")

          // this.estimatedDeliveryTime = Math.round(data.deliveryOffers[0].estimatedDrivingTime / 60) > this.venue.estimatedPreparationTime ? Math.floor(data.deliveryOffers[0].eta_to_pick_up / 60) + Math.floor(data.deliveryOffers[0].estimatedDrivingTime / 60) : Math.floor(data.deliveryOffers[0].estimatedDrivingTime / 60) + this.venue.estimatedPreparationTime

          // this.estimatedDeliveryTime15 = Math.round(data.deliveryOffers[0].estimatedDrivingTime / 60) > this.venue.estimatedPreparationTime ? Math.round(data.deliveryOffers[0].eta_to_pick_up / 60) + Math.round(data.eta_to_delivery / 60) + 15 : Math.round(data.eta_to_delivery / 60) + this.venue.estimatedPreparationTime + 15

          // // console.log("esta es la pregunta Math.round(data.eta_to_pick_up / 60) >  this.venue.estimatedPreparationTime ", Math.round(data.eta_to_pick_up / 60) > this.venue.estimatedPreparationTime, Math.round(data.eta_to_pick_up / 60), this.venue.estimatedPreparationTime);

        }
      },
      (error) => {
        console.error("error peya", error);
      }
    );
  }


  addParcelandestimateDelivery(logistic?: string) {

    if (this.deliveryAddress) {

      var orderInfo = {
        "parcels": [
          {
            "pickup_info": {
              "addr": this.address,
              "contact": {
                "name": "nombre",
                "phone": "+5491151136011"
              },
              "instr": "Probando el costo de envio",
              "loc": {
                "lat": this.lat,
                "lon": this.lng
              }
            },
            "dropoff_info": {
              "addr": this.deliveryAddress.address,
              "contact": {
                "name": "Joaquin Gomez",
                "phone": "111111111"
              },
              "instr": "Probando el costo de envio",
              "loc": {
                "lat": this.deliveryAddress.lat,
                "lon": this.deliveryAddress.lng
              }
            },
            "dimensions": {
              "height": 2,
              "length": 2,
              "width": 2,
              "unit": "UNIT"
            },
            "weight": {
              "value": 3,
              "unit": "2"
            }
          }
        ]
      };
      // console.log("este es order info", orderInfo);

      localStorage.removeItem("cabifyId")

      this.api.addNewParcel({ body: orderInfo })
        .subscribe(async (data) => {
          // console.log("la vuelta", data);

          if (data && data.parcels) {

            localStorage.setItem("cabifyId", data.parcels[0].id);
            // console.log("deberia haber data y data.id", data);

            let cabifyId = localStorage.getItem("cabifyId")
            // console.log("este es el id del cabify", cabifyId);

            let info = {
              "parcel_ids": [
                cabifyId
              ],
              "mp_commission": this.mp_commission
            };

            (await this.api.estimateDelivery({ body: info }))
              .subscribe((data) => {
                // console.log("la vuelta del costo de envio cabify", data);

                if (data) {
                  if (logistic == 'peya') {

                    this.estimatedDeliveryTime = Math.round(data.eta_to_pick_up / 60) > this.venue.estimatedPreparationTime ? Math.floor(data.eta_to_pick_up / 60) + Math.floor(data.eta_to_delivery / 60) : Math.floor(data.eta_to_delivery / 60) + this.venue.estimatedPreparationTime

                    this.estimatedDeliveryTime15 = Math.round(data.eta_to_pick_up / 60) > this.venue.estimatedPreparationTime ? Math.round(data.eta_to_pick_up / 60) + Math.round(data.eta_to_delivery / 60) + 15 : Math.round(data.eta_to_delivery / 60) + this.venue.estimatedPreparationTime + 15

                  } else {

                    // console.log("esta el resto?", this.venue);
                    // console.log(data.price_total.amount);

                    let dummyPrice = String(data.price_total.amount)
                    this.deliveryCharge = parseFloat(dummyPrice.substring(0, dummyPrice.length - 2) + "." + dummyPrice.substring(dummyPrice.length - 2))
                    //  this.deliveryCharge = parseFloat(String(dummyPrice).slice(0,3) +"."+ String(dummyPrice).slice(3))


                    this.estimatedDeliveryTime = Math.round(data.eta_to_pick_up / 60) > this.venue.estimatedPreparationTime ? Math.floor(data.eta_to_pick_up / 60) + Math.floor(data.eta_to_delivery / 60) : Math.floor(data.eta_to_delivery / 60) + this.venue.estimatedPreparationTime

                    this.estimatedDeliveryTime15 = Math.round(data.eta_to_pick_up / 60) > this.venue.estimatedPreparationTime ? Math.round(data.eta_to_pick_up / 60) + Math.round(data.eta_to_delivery / 60) + 15 : Math.round(data.eta_to_delivery / 60) + this.venue.estimatedPreparationTime + 15

                    // console.log("esta es la pregunta Math.round(data.eta_to_pick_up / 60) >  this.venue.estimatedPreparationTime ", Math.round(data.eta_to_pick_up / 60) > this.venue.estimatedPreparationTime, Math.round(data.eta_to_pick_up / 60), this.venue.estimatedPreparationTime);
                  }

                }

              }, error => {
                this.cabifyError = true
                this.api.cabifyError("estimated delivery", error)
                console.error("error cabify", error)
              });


          }

        }, error => {
          this.cabifyError = true
          this.api.cabifyError("addNewParcelCategory", error)
          console.error("error cabify", error)
        });
    }
    // console.log("termino la funcion de addnewparcel");

  }

  toggle() {
    console.log('Toggle clicked. Current state:', this.isToggled);
    console.log("Si el toggle es true: Estas en modo Vegan")
    this.isToggled = !this.isToggled;
    console.log("Ahora el toggle es", this.isToggled)
    if(this.isToggled){
      this.util.notificationSuccess(`Se activo el modo Vegan`)
      localStorage.setItem('diet_type', 'vegan')
      this.getFoods('vegan')
    }else{
      this.util.notificationSuccess(`Se desactivo el modo Vegan`)
      localStorage.setItem('diet_type', 'vegetarian')
      this.getFoods('veggie')

    }
    setTimeout(() => {
      console.log('Temporary state reset.');
    }, 2000);
  }

  async ngOnInit() {
    this.route.queryParams.subscribe((data) => {
      // console.log("data=>", data);
      if (data.hasOwnProperty("id")) {
        this.id = data.id;
        this.getAddress();
        this.getVenueDetails();
        this.DELIVERY_RANGE = this.firebaseConfigService.getDeliveryRange();
        // this.estimateDelivery()
      }
    });

    // this.DELIVERY_PRICE = await this.firebaseConfigService.getDeliveryPrice();
  }

  onScroll(event) {
    if (event.detail.deltaY > 0 && this.headerHidden) return;
    if (event.detail.deltaY < 0 && !this.headerHidden) return;
    if (event.detail.deltaY > 80) {
      this.headerHidden = true;
    } else {
      this.headerHidden = false;
    }
  }

  protected resetChanges = () => {
    this.foods = this.dummyFoods;
    this.dummyCategories = this.categories
    this.featured = this.dummyFeatured
  }

  filterItems(searchTerm) {
    this.foods = this.dummyFoods.filter((x) =>
      this.tacc ? x.tacc === this.tacc : true
    );
    this.featured = this.dummyFeatured.filter((x) =>
      this.tacc ? x.tacc === this.tacc : true
    );

    this.dummyCategories = this.categories.filter((cat) =>
      this.foods.some((food) => food.cid.id === cat.id)
    );
    let foodsParaMandar = this.foods.filter((item) => {
      return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
    this.featured = this.featured.filter((item) => {
      return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
    // console.log("como estan mis categorias", this.dummyCategories)
    let categoriasQueQuedan = this.dummyCategories.filter(cate => {
      let puede = false
      foodsParaMandar.forEach(element => {
        if (element.cid.id == cate.id) {
          puede = true
        }
      });
      return puede
    })
    this.dummyCategories = categoriasQueQuedan
    // console.log("foods que quedan", foodsParaMandar);

    // console.log("las categorias que quedan", categoriasQueQuedan);

    return foodsParaMandar

  }

  onSearchChange(event) {
    this.resetChanges();
    this.foods = this.filterItems(event.detail.value);
    // this.featured = this.filterItems(event.detail.value);
    // console.log("los que quedan son", this.foods);
    this.calculate()
  }
  checkDistance(distance) {
    // console.log("this.distance", distance, this.DELIVERY_RANGE.__zone_symbol__value);

    if (distance > this.DELIVERY_RANGE.__zone_symbol__value) {
      swal.fire({
        title: '',
        text: 'El local no llega hasta tu dirección',
        showCancelButton: true,
        backdrop: false,
        background: 'white',
        confirmButtonText: 'Cambiar dirección',
        cancelButtonText: 'Entendido',
      }).then((data) => {
        // console.log(data);
        if (data && data.value) {
          this.changeAddress()
        }
        else {
          this.isNear = false
          this.isAvailable()
        }
      })

    } else {
      this.isNear = true
      this.isAvailable()
    }
  }

  isAvailable() {
    if (this.isNear && this.isOpen) {
      this.available = true
    } else {
      this.available = false
    }
  }

  changeAddress() {
    const navData: NavigationExtras = {
      queryParams: {
        from: 'category',
        id: this.id,
        venueLat: this.lat,
        venueLng: this.lng
      },
    };
    this.router.navigate(['choose-address'], navData);
  }

  getAddress() {
    const address = JSON.parse(localStorage.getItem("deliveryAddress"));
    if (address && address.address) {
      this.deliveryAddress = address;
    }
    // console.log("this.deliveryAddress", this.deliveryAddress);

    return this.deliveryAddress;
  }
  isVenueOpen(schedule) {
    const currentDay = new Date().getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();

    // console.log(`Current Day: ${currentDay}, Current Time: ${currentHour}:${currentMinute}`);

    if (schedule.hasOwnProperty(currentDay) && schedule[currentDay].length === 2) {
      const openingTime = schedule[currentDay][0].open;
      const closingTime = schedule[currentDay][1].close;

      if (openingTime && closingTime) {
        const [openHour, openMinute] = openingTime.split(':').map(Number);
        let [closeHour, closeMinute] = closingTime.split(':').map(Number);

        // Treat 00:00 as midnight
        if (closeHour === 0 && closeMinute === 0) {
          closeHour = 24;
        }

        const currentTimeInMinutes = currentHour * 60 + currentMinute;
        const openingTimeInMinutes = openHour * 60 + openMinute;
        const closingTimeInMinutes = closeHour * 60 + closeMinute;

        return currentTimeInMinutes >= openingTimeInMinutes && currentTimeInMinutes <= closingTimeInMinutes;
      }
    }

    return false;
  }



  getVenueDetails() {
    // Venue Details
    this.api
      .getVenueDetails(this.id)
      .then(
        async (data) => {
          console.log("RESTTTTTTTTTTTT", data);
          if (data) {
           
            this.takeaway_available = data.takeaway_available ? true : false
            if(!this.takeaway_available){
               this.takeaway = false
               localStorage.setItem('takeaway', "false")
              }
            this.cheapest_delivery = data.cheapest_delivery ? data.cheapest_delivery : 'cabify_bike'
            this.venue = data;
            this.name = data.name;
            this.descriptions = data.descriptions;
            this.cover = data.cover
            // this.cover = data.cover;
            
            this.rating = Math.round((data.nps_sum / data.feedback_amount) * 10) / 10
            this.feedback_amount = data.feedback_amount
            this.logo = data.logo;
            this.address = data.address;
            this.ratting = data.ratting ? data.ratting : 0;
            this.totalRatting = data.totalRatting ? data.totalRatting : 2;
            this.dishPrice = data.dishPrice;
            this.time = data.time;
            this.cusine = data.cusine;
            this.type = data.types;
            this.schedule = data.schedule;
            this.vegan_venue = data.vegan_venue;
            this.vegan = data.vegan ? data.vegan : false;
            this.veggie = data.veggie ? data.veggie : false;
            this.lat = data.lat;
            this.lng = data.lng;
            this.estimatedPreparationTime = data.estimatedPreparationTime
            this.show_gluten = data.types.includes("fashion") ? false : true
            this.mp_commission = data.mp_commission
            if (this.deliveryAddress.house) {
              this.distance = await this.distanceInKmBetweenEarthCoordinates(
                this.deliveryAddress.lat,
                this.deliveryAddress.lng,
                this.lat,
                this.lng
              );
            } else if (localStorage.getItem("myLat")) {
              this.distance = await this.distanceInKmBetweenEarthCoordinates(
                localStorage.getItem("myLat"),
                localStorage.getItem("myLng"),
                this.lat,
                this.lng
              );
            }

            if (this.distance > 4 && !localStorage.getItem("not_show_long_distance")) {
              // console.log("esta es la distancia", this.distance)
              this.util.showCustomAlertConfirmOnlyWithCheckbox("Estás a más de 4 km, el costo de envío puede ser elevado.", "", "./assets/imgs/logopng-Negro.png", "No volver a mostrar", this.show_long_distance.bind(this))

              // this.util.showToast(
              //   "Estás a más de 4 km, el costo de envío puede ser elevado.",
              //   "secondary",
              //   "bottom",
              //   4000
              // );
            }



            let status = false;
            if (data.status == "open") {
              status = true;
            }

            let isClose = false;
            if (data.isClose) {
              isClose = true;
            }

            let current = false;
            if (status && !isClose) {
              current = true;
            }

            let diaHoy = new Date().getDay();
            let horaHoy = new Date().getHours();
            let minutosHoy = new Date().getMinutes();

            // console.log(`Hoy es, ${diaHoy}, son las ${horaHoy} : ${minutosHoy}`)
            if (diaHoy == 0) {
              diaHoy = 6;
            } else if (diaHoy == 1) {
              diaHoy = 0;
            } else if (diaHoy == 2) {
              diaHoy = 1;
            } else if (diaHoy == 3) {
              diaHoy = 2;
            } else if (diaHoy == 4) {
              diaHoy = 3;
            } else if (diaHoy == 5) {
              diaHoy = 4;
            } else if (diaHoy == 6) {
              diaHoy = 5;
            }



            const scheduleToday = this.schedule[diaHoy];

            // console.log("SCHEDULE TODAY", scheduleToday);

            let horario0Hoy = {
              hor0open: 0,
              hor0close: 0,
              min0open: 0,
              min0close: 0,
            };

            let horario1Hoy = {
              hor1open: 0,
              hor1close: 0,
              min1open: 0,
              min1close: 0,
            };

            if (scheduleToday[0].open != "") {
              // console.log("scheduleToday[0]", scheduleToday[0]);

              horario0Hoy = {
                hor0open: parseInt(scheduleToday[0].open.split(":")[0]),
                hor0close: parseInt(scheduleToday[0].close.split(":")[0]),
                min0open: parseInt(scheduleToday[0].open.split(":")[1]),
                min0close: parseInt(scheduleToday[0].close.split(":")[1]),
              };
            }

            if (scheduleToday[1] != "") {
              // console.log("scheduleToday[1]", scheduleToday[1]);

              horario1Hoy = {
                hor1open: parseInt(scheduleToday[1].open.split(":")[0]),
                hor1close: parseInt(scheduleToday[1].close.split(":")[0]),
                min1open: parseInt(scheduleToday[1].open.split(":")[1]),
                min1close: parseInt(scheduleToday[1].close.split(":")[1]),
              };
            }

            // console.log("SCHEDULE TODAY", horario0Hoy, horario1Hoy);
            if (current) {
              if (
                (horaHoy >= horario0Hoy.hor0open && horaHoy <= horario0Hoy.hor0close) ||
                (horaHoy >= horario1Hoy.hor1open && horaHoy <= horario1Hoy.hor1close)
                //Aca ya esta dentro de la hora, ahora hay que ver minutos
              ) {
                // A revisar los minutitos
                if (
                  (horario0Hoy.hor0open == horaHoy ||
                    horario1Hoy.hor1open == horaHoy) &&
                  (minutosHoy >= horario0Hoy.min0open ||
                    minutosHoy >= horario1Hoy.min1open)
                ) { // SI es la misma hora en la que abre y los minutos de ahora son mas altos de los que abre

                  this.isOpen = true;
                } else if (
                  (horario0Hoy.hor0close == horaHoy ||
                    horario1Hoy.hor1close == horaHoy) &&
                  (horario0Hoy.min0close >= minutosHoy ||
                    horario1Hoy.min1close >= minutosHoy)
                ) {

                  this.isOpen = true;
                } else if (
                  horario0Hoy.hor0open == horaHoy ||
                  horario1Hoy.hor1open == horaHoy ||
                  horario0Hoy.hor0close == horaHoy ||
                  horario1Hoy.hor1close == horaHoy
                ) {

                  this.isOpen = false;
                } else {

                  this.isOpen = true;
                }
              } else {
                //Ya x las horas te digo que no esta abierto

                this.isOpen = false;
              }
            } else {

              this.isOpen = false;
            }

            const scheduleToday0 =
              scheduleToday[0].open + " - " + scheduleToday[0].close;
            const scheduleToday1 =
              scheduleToday[1].open + " - " + scheduleToday[1].close;

            if (!current) {
              this.scheduleToday = "Cerrado";
            } else {
              if (scheduleToday[0].open != "" && scheduleToday[1].open != "") {
                this.scheduleToday = scheduleToday0 + " y " + scheduleToday1;
              } else if (scheduleToday[0].open != "") {
                this.scheduleToday = scheduleToday0;
              } else if (scheduleToday[1].open != "") {
                this.scheduleToday = scheduleToday1;
              } else {
                this.scheduleToday = "Cerrado";
              }
            }



            // console.log("schedule!", this.scheduleToday);

            await this.getEstimateTime();
            await this.getFoods();
            await this.getCates();
            await this.isAvailable();

            this.estimatedDelivery()

          }
        },
        (error) => {
          // console.log(error);
          this.util.errorToast(this.util.translate("Something went wrong"));
        }
      )
      .catch((error) => {
        // console.log(error);
        this.util.errorToast(this.util.translate("Something went wrong"));
      });
  }


  show_long_distance(data, status) {
    console.log(data, status)
    if (status) {
      localStorage.setItem("not_show_long_distance", "true")
    }
  }
  async estimatedDelivery() {
    const info_estimation = JSON.stringify({
      pickup_information: {
        phone: "+5491151136011",
        name: this.name,
        address: this.address,
        vid: this.id,
        lat: this.lat,
        lng: this.lng,
        floor: this.venue.floor,
        landmark: this.venue.landmark,
      },
      dropoff_information: {
        phone: "+5491151136011",
        name: "Milton",
        address: this.deliveryAddress.address,
        uid: localStorage.getItem("uid") ? localStorage.getItem("uid") : '',
        lat: this.deliveryAddress.lat,
        lng: this.deliveryAddress.lng,
        house: this.deliveryAddress.house,
        landmark: this.deliveryAddress.landmark
      },
      cheapest_delivery: this.cheapest_delivery,
      mp_commission: this.mp_commission,
      venue_estimatedPreparationTime: this.venue.estimatedPreparationTime,
      proof_of_delivery : 'code'
    });

       (await this.api.estimateDeliveryGeneral({ body: info_estimation })).subscribe(data => {

        if (data) {


          this.estimatedDeliveryTime = data.eta_to_delivery[0]

          this.estimatedDeliveryTime15 = data.eta_to_delivery[1]
          this.final_delivery = data.final_delivery ? data.final_delivery : this.cheapest_delivery

          let dummyPrice = String(data.price)
          this.deliveryCharge = dummyPrice
          if (this.final_delivery == 'peya') {
            // console.log("peya info",data)
            localStorage.setItem("peyaId", data.peya_id);
          } else {
            localStorage.setItem("cabifyId", data.cabify_id);
          }
          localStorage.setItem('final_delivery', this.final_delivery)
        }



      })

  }

  async distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    // console.log(lat1, lon1, lat2, lon2);
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusKm * c;
    const distanceRound = Math.round(distance * 100) / 100;
    // this.deliveryCharge = Math.round(100 + this.DELIVERY_PRICE * distanceRound);
    this.checkDistance(distanceRound)

    return distanceRound;
  }

  degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  getEstimateTime() {
    const add = JSON.parse(localStorage.getItem("deliveryAddress"));
    // console.log("ADDDDDDDDDDDS", add);
    if (add && add.address) {
      const lat = add.lat;
      const lng = add.lng;
      const citys = JSON.parse(localStorage.getItem("selectedCity"));
      const city = citys.id;
      // console.log("CITYYYYYYYYYYYY", city, "LAT", lat, "LNG", lng);
      this.route.queryParams.subscribe((data) => {
        data.hasOwnProperty("id");
        // console.log("estes es els supuestos id", data);

        const id = data.id;
        // console.log("this.type", this.type);

        this.api.getVenues({ type: this.type[0], lng: lng, lat: lat, cityId: city }).subscribe((datas) => {
          // console.log("getRest", datas);
          let venues = datas.venues
          let venuesClosed = datas.venuesClosed
          let venuesOut = datas.venuesOut

          let emptyArray = []

          let allRests = emptyArray.concat(venues, venuesClosed, venuesOut);
          // console.log("allRests", allRests);

          allRests.forEach((element) => {
            if (element.id == id) {
              // console.log("ESTE RESTTT", element);

              // this.estimatedDeliveryTime = element.estimatedDeliveryTime + 5;
              // this.estimatedDeliveryTime15 =
              //   element.estimatedDeliveryTime + 20;
              this.cd.detectChanges();
            }
          });
        });
      });
    }
  }

  getCates() {
    this.api
      .getVenueCategories(this.id)
      .then(
        (cate) => {
          // console.log(cate);

          this.categories = [];
          this.dummyCategories = [];
          if (cate) {
            this.categories = cate;
            this.dummyCategories = cate.filter((cat) =>
              this.foods.some((food) => food.cid.id === cat.id)
            );
            // console.log("CATEGPRYYYYYS", this.dummyCategories);
          }
        },
        (error) => {
          // console.log(error);
          this.dummy = [];
          this.util.errorToast(this.util.translate("Something went wrong"));
        }
      )
      .catch((error) => {
        // console.log(error);
        this.dummy = [];
        this.util.errorToast(this.util.translate("Something went wrong"));
      });
  }
   change_url(input_url) {
    // Define the part to be replaced and the replacement
    const partToReplace = 'https://firebasestorage.googleapis.com/v0/b/v-go-app.appspot.com/o/';
    const replacement = 'http://34.117.68.250/';

    // Perform the replacement
    const modified_url = input_url.replace(partToReplace, replacement);

    // Return the modified URL
    return modified_url;
}

 

  getFoods(type?) {
    if(this.isToggled){
      type = 'vegan'
    }else{
      type = 'veggie'
    }
    this.api
      .getFoods(this.id, type)
      .then(
        (foods) => {
          if (foods) {
            console.log(foods)
            // if()
            this.dummy = [];
            this.foods = [];
            this.featured = [];
            this.dummyFeatured = [];
            this.dummyFoods = [];
            foods.sort((a, b) => { return a.order - b.order })
            foods.forEach((element) => {
              if (element && element.status === true) {
                let new_cover = element.cover
                const info = {
                  cid: {
                    id: element.cid.id,
                  },
                  christmas: element.christmas ? element.christmas : false,
                  christmas_scheduled: element.christmas_scheduled ? element.christmas_scheduled : false,
                  cover: new_cover,
                  covers: element.covers ? element.covers : [element.cover],
                  desc: element.desc,
                  id: element.id,
                  name: element.name,
                  price: element.price,
                  discount: Math.round(element.discount),
                  originalPrice: element.originalPrice,
                  ratting: element.ratting,
                  uid: element.uid,
                  tacc: element.tacc,
                  quantiy: 0,
                  size: element.size,
                  product_variations: element.product_variations ? element.product_variations : element.variations,
                  variations: element.variations ? element.variations : element.product_variations,
                  totalRatting: element.totalRatting ? element.totalRatting : 0,
                  selectedItem: [],
                  veggie: element.veggie? true : false,
                  star: element.star ? element.star : false
                };
                this.foods.push(info);
                this.dummyFoods.push(info);
                this.foodIds.push(element.id);
                if (element.star) {
                  this.featured.push(info)
                  this.dummyFeatured.push(info)
                }
              }
            });
            // console.log("myfoods", this.foods);
            if (!this.foods.length || this.foods.length === 0) {
              this.util.errorToast(this.util.translate("No Foods found"));
              //this.navCtrl.back();
              this.navCtrl.pop();
              return false;
            }
            this.changeStatus();
            this.checkCart();
          }
        },
        (error) => {
          // console.log(error);
          this.dummy = [];
          this.util.errorToast(this.util.translate("Something went wrong"));
        }
      )
      .catch((error) => {
        // console.log(error);
        this.dummy = [];
        this.util.errorToast(this.util.translate("Something went wrong"));
      });
  }

  checkCart() {
    const userCart = localStorage.getItem("userCart");
    if (
      userCart &&
      userCart !== "null" &&
      userCart !== undefined &&
      userCart !== "undefined"
    ) {
      const cart = JSON.parse(userCart);
      // console.log("carrt", cart);
      // console.log(this.foodIds);
      cart.forEach((element) => {
        if (this.foodIds.includes(element.id)) {
          const index = this.foods.findIndex((x) => x.id === element.id);
          // console.log("index---<", index);
          this.foods[index].quantiy = element.quantiy;
          this.foods[index].selectedItem = element.selectedItem;
        }
      });
    }
    const vid = localStorage.getItem("vid");
    if (vid && vid !== this.id) {
      // console.log("tiene algo en el carrito que no es de este local",vid, this.id);

    } else {

      this.calculate();
    }
  }
  back() {
    //this.navCtrl.navigateRoot(['tabs']);
    //this.navCtrl.back()
    this.navCtrl.pop();
  }

  getCusine(cusine) {
    return cusine.join("-");
  }
  add(index) {

    const vid = localStorage.getItem("vid");
    if (vid && vid !== this.id) {
      this.presentAlertConfirm();
      return false;
    }
    if (
      (this.foods[index].variations &&
      this.foods[index].variations.length) || (this.foods[index].product_variations &&
        this.foods[index].product_variations.length)
    ) {
      // console.log("open modal");
      this.openVariations(index, this.isOpen);
    } else {
      if (this.foods[index].christmas && this.foods[index].christmas_scheduled) {
        Swal.fire({
          title: "Agregar al carrito",
          text: "La entrega de este producto es durante el día 24 de diciembre",
          icon: 'question',
          confirmButtonText: "Agregar",
          backdrop: false,
          background: 'white',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "No"
        }).then(data => {
          // console.log(data);
          if (data && data.value) {
            this.foods[index].quantiy = 1;
            this.calculate();
          }
        });

      } else {
        this.foods[index].quantiy = 1;
        this.calculate();
        let item_info = this.foods[index]

        const item = {
          item_id: item_info.id,
          item_name: item_info.name,
          quantity: 1,
          price: item_info.price,
        };
        
        // Create the event data
        const addToCartData = {
          currency: 'ARS',
          items: [item],
        };
        
        // Log the add_to_cart event
        FirebaseAnalytics.logEvent({
          name: 'add_to_cart',
          params: {
            currency: addToCartData.currency,
            items: addToCartData.items,
          },
        }).then(() => {
          console.log('Logged event: add_to_cart');
        }).catch((error) => {
          console.error('Error logging add_to_cart event:', error);
        });
      }
    }
    // } else {

    // this.router.navigate(["login"], {
    // queryParams: {
    // from: `category`,
    // id: this.id,
    // },
    // });

    // this.util.errorToast(this.util.translate('In order to continue we need you to log in.'));
    // }
    // })
    // .catch((error) => {
    // // console.log(error);
    // });
    let timestamp = Math.floor(Date.now() / 1000);
    OneSignal.sendTags({
      "cart_update": timestamp
    });
  }


  goToCart() {
    // console.log("entra en gotocart");
    this.router.navigate(["cart"]);
  }

  statusChange() {
    // console.log("status", this.tacc);
    this.changeStatus();
  }
  calculate() {
    // this.dummy = [];
    // // console.log('khaliiii', this.dummy);
    // // console.log(this.foods);
    // let item = this.foods.filter(x => x.quantiy > 0);
    // // console.log(item);
    // this.totalPrice = 0;
    // this.totalItem = 0;
    // item.forEach(element => {
    //   this.totalItem = this.totalItem + element.quantiy;
    //   this.totalPrice = this.totalPrice + (parseFloat(element.price) * parseInt(element.quantiy));
    // });
    // this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
    // // console.log('total item', this.totalItem);
    // if (this.totalItem === 0) {
    //   this.totalItem = 0;
    //   this.totalPrice = 0;
    // }
    this.dummy = [];
    // console.log("khaliiii", this.dummy);
    // console.log(this.foods);
    let item = this.foods.filter((x) => x.quantiy > 0);
    this.foods.forEach((element) => {
      if (element.quantiy === 0) {
        element.selectedItem = [];
      }
    });
    // console.log("item=====>>", item);
    this.totalPrice = 0;
    this.totalItem = 0;
    this.cart = [];
    // console.log("cart emplth", this.cart, item);
    item.forEach((element) => {
      this.originalPrice = element.originalPrice;
      this.totalItem = this.totalItem + element.quantiy;
      console.log("itemsss----->>>", element);
      if (element && element.selectedItem && element.selectedItem.length > 0) {
        // console.log("tiene mucho para ", element);

        let subPrice = 0;
        element.selectedItem.forEach((subItems) => {
          subItems.item.forEach((realsItems) => {
            subPrice = subPrice + realsItems.value;
          });
          subPrice = (subPrice + parseFloat(element.price)) * subItems.total;
        });
        this.totalPrice = parseInt(this.totalPrice + subPrice);
        // this.totalPrice = this.totalPrice + (subPrice * parseInt(element.quantiy));
      } else {
        // console.log(element, "el elemento tiene cantos quantity y q valor?")
        this.totalPrice =
          this.totalPrice +
          parseFloat(element.price) * parseInt(element.quantiy);
      }
      this.cart.push(element);
    });
    localStorage.removeItem("userCart");
    // console.log("carrrrrrr---->>>", this.cart);
    localStorage.setItem("userCart", JSON.stringify(this.cart));
    this.totalPrice = parseInt(this.totalPrice);
    // console.log("total item", this.totalItem);
    if (this.totalItem === 0) {
      this.totalItem = 0;
      this.totalPrice = 0;
    }
  }

  async setData() {
    const vid = localStorage.getItem("vid");
    // console.log("leaving the planet", vid, this.id);
    // console.log("total item", this.totalItem);

    if (vid && vid === this.id && this.totalPrice > 0) {
      localStorage.setItem("vid", this.id);
      await localStorage.setItem("foods", JSON.stringify(this.foods));
      localStorage.setItem("categories", JSON.stringify(this.categories));
      localStorage.setItem("dummyItem", JSON.stringify(this.dummyFoods));
      localStorage.setItem("totalItem", this.totalItem);
      localStorage.setItem("totalPrice", this.totalPrice);
    } else if (!vid && this.totalItem > 0) {
      localStorage.setItem("vid", this.id);
      await localStorage.setItem("foods", JSON.stringify(this.foods));
      localStorage.setItem("categories", JSON.stringify(this.categories));
      localStorage.setItem("dummyItem", JSON.stringify(this.dummyFoods));
      localStorage.setItem("totalItem", this.totalItem);
      localStorage.setItem("totalPrice", this.totalPrice);
    } else if (this.totalItem == 0) {
      this.totalItem = 0;
      this.totalPrice = 0;
    }
  }
  async ionViewWillLeave() {
    await this.setData();
  }
  changeStatus() {
    this.foods = this.dummyFoods.filter((x) =>
      this.tacc ? x.tacc === this.tacc : true
    );
    this.featured = this.dummyFeatured.filter((x) =>
      this.tacc ? x.tacc === this.tacc : true
    );
    this.dummyCategories = this.categories.filter((cat) =>
      this.foods.some((food) => food.cid.id === cat.id)
    );
    // console.log("thhis.foods", this.foods);

  }
  // addQ(index) {
  //   this.foods[index].quantiy = this.foods[index].quantiy + 1;
  //   this.calculate();
  // }
  // removeQ(index) {
  //   if (this.foods[index].quantiy !== 0) {
  //     this.foods[index].quantiy = this.foods[index].quantiy - 1;
  //   } else {
  //     this.foods[index].quantiy = 0;
  //   }
  //   this.calculate();
  // }

  async openVariations(index, open) {
    // console.log(index, open);

    if (open) {
      const modal = await this.modalCtrl.create({
        component: VariationsPage,
        cssClass: "custom_modal2",
        componentProps: {
          name: this.name,
          food: this.foods[index],
          scheduleToday: this.scheduleToday,
          vegan_mode: this.isToggled
        },
      });
      let item_info = this.foods[index]
      const item = {
        item_id: item_info.id,
        item_name: item_info.name,
        quantity: item_info.quantiy,
        price: item_info.price,
       };
      
      // Create the event data
      const viewItemData = {
        currency: 'ARS',
        items: [item],
      };
      
      // Log the view_item event
      FirebaseAnalytics.logEvent({
        name: 'view_item',
        params: {
          currency: viewItemData.currency,
          items: viewItemData.items,
        },
      }).then(() => {
        console.log('Logged event: view_item');
      }).catch((error) => {
        console.error('Error logging view_item event:', error);
      });

      //ACA
      modal.onDidDismiss().then((data) => {
        console.log("from variations", data);
        // console.log("data.data", data.role);
        let isValid = false;
        if (data.role === "new") {
          this.foods[index].quantiy += 1;
          if (this.foods[index].product_variations && this.foods[index].product_variations.length) {
            const carts = {
              item: data.data,
              total: 1,
            };
            this.foods[index].selectedItem.push(carts);
          }
          

          isValid = true;

          let item_info = this.foods[index]

          const item = {
            item_id: item_info.id,
            item_name: item_info.name,
            quantity: item_info.quantiy,
            price: item_info.price,
           };
        
        // Create the event data
        const addToCartData = {
          currency: 'ARS',
          items: [item],
        };
        
        // Log the add_to_cart event
        FirebaseAnalytics.logEvent({
          name: 'add_to_cart',
          params: {
            currency: addToCartData.currency,
            items: addToCartData.items,
          },
        }).then(() => {
          console.log('Logged event: add_to_cart');
        }).catch((error) => {
          console.error('Error logging add_to_cart event:', error);
        });

        } else if (data.role === "sameChoice") {
          this.foods[index].selectedItem = data.data;
          //nueva logica
          this.foods[index].quantiy = this.foods[index].selectedItem.reduce(
            (total, item) => {
              return total + item.total;
            },
            0
          );
          //this.foods[index].quantiy = this.foods[index].selectedItem.length;

          isValid = true;
        } else if (data.role === "newCustom") {
          const carts = {
            item: data.data,
            total: 1,
          };
          this.foods[index].selectedItem.push(carts);
          this.foods[index].quantiy = this.foods[index].quantiy + 1;
          isValid = true;
        } else if (data.role === "remove") {
          // console.log("number", data.data);
          this.foods[index].quantiy = 0;
          this.foods[index].selectedItem = [];
          isValid = true;
        } else {
          // console.log("empy");
        }
        if (isValid) {
          console.log("el item en this.foods", this.foods[index])
          // console.log("isValid", isValid);
          this.calculate();
        }
      });
      return await modal.present();
    } else {
      // console.log("resto cerrado");
    }
  }
  async openVariationsFeatured(index, open) {
    // console.log(index, open);

    if (open) {
      const modal = await this.modalCtrl.create({
        component: VariationsPage,
        cssClass: "custom_modal2",
        componentProps: {
          name: this.name,
          food: this.featured[index],
          scheduleToday: this.scheduleToday,
          vegan_mode: this.isToggled
        },
      });
      //ACA
      modal.onDidDismiss().then((data) => {
        // console.log("from variations", data.data);
        // console.log("data.data", data.role);
        let isValid = false;
        if (data.role === "new") {
          this.featured[index].quantiy += 1;
          const carts = {
            item: data.data,
            total: 1,
          };
          this.featured[index].selectedItem.push(carts);
          isValid = true;
        } else if (data.role === "sameChoice") {
          this.featured[index].selectedItem = data.data;
          //nueva logica
          this.featured[index].quantiy = this.featured[index].selectedItem.reduce(
            (total, item) => {
              return total + item.total;
            },
            0
          );
          //this.featured[index].quantiy = this.featured[index].selectedItem.length;

          isValid = true;
        } else if (data.role === "newCustom") {
          const carts = {
            item: data.data,
            total: 1,
          };
          this.featured[index].selectedItem.push(carts);
          this.featured[index].quantiy = this.featured[index].quantiy + 1;
          isValid = true;
        } else if (data.role === "remove") {
          // console.log("number", data.data);
          this.featured[index].quantiy = 0;
          this.featured[index].selectedItem = [];
          isValid = true;
        } else {
          // console.log("empy");
        }
        if (isValid) {
          // console.log("isValid", isValid);
          this.calculate();
        }
      });
      return await modal.present();
    } else {
      // console.log("resto cerrado");
    }
  }

  addQ(index) {
    let product = this.foods[index]
    // console.log("foooduieeeee===========>>", product);
    if (product.variations && product.variations.length) {
      this.openVariations(index, this.isOpen);
    } else {
      product.quantiy = product.quantiy + 1;
      this.calculate();
    }
    const item = {
      item_id: product.id,
      item_name: product.name,
      quantity: 1,
      price: product.price,
    };
    
    // Create the event data
    const addToCartData = {
      currency: 'ARS',
      items: [item],
    };
    
    // Log the add_to_cart event
    FirebaseAnalytics.logEvent({
      name: 'add_to_cart',
      params: {
        currency: addToCartData.currency,
        items: addToCartData.items,
      },
    }).then(() => {
      console.log('Logged event: add_to_cart');
    }).catch((error) => {
      console.error('Error logging add_to_cart event:', error);
    });

   
  }

  removeQ(index) {
    let product = this.foods[index]

    if (product.quantiy !== 0) {
      if (product.quantiy >= 1 && !product.size) {
        if (!this.hasVariations(index) || product.quantiy == 1) {

          product.quantiy = product.quantiy - 1;
         
          const item = {
            item_id: product.id,
            item_name: product.name,
            quantity: 1,
            price: product.price,
          };
          
          // Create the event data
          const addToCartData = {
            currency: 'ARS',
            items: [item],
          };
          
          // Log the add_to_cart event
          FirebaseAnalytics.logEvent({
            name: 'remove_from_cart',
            params: {
              currency: addToCartData.currency,
              items: addToCartData.items,
            },
          }).then(() => {
            console.log('Logged event: add_to_cart');
          }).catch((error) => {
            console.error('Error logging add_to_cart event:', error);
          });
      
        } else {
          this.goToCart()
        }
      } else {
        this.openVariations(index, this.isOpen);
      }
    } else {
      product.quantiy = 0;
    }
    this.calculate();

  }

  hasVariations(index) {
    let product = this.foods[index]
    if (product.variations && product.variations.length > 0) {
      return true

    } else {
      return false
    }
  }
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: this.util.translate("Warning"),
      message: this.util.translate(
        `you already have item's in cart with different restaurant`
      ),
      buttons: [
        {
          text: this.util.translate("Cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log("Confirm Cancel: blah");
            this.back();
          },
        },
        {
          text: this.util.translate("Clear cart"),
          handler: () => {
            // console.log("Confirm Okay");
            localStorage.removeItem("vid");
            this.dummy = Array(10);
            localStorage.removeItem("categories");
            localStorage.removeItem("dummyItem");
            localStorage.removeItem("userCart");
            localStorage.removeItem("foods");
            localStorage.removeItem('takeaway')
            this.takeaway = false
            this.totalItem = 0;
            this.totalPrice = 0;
            this.getCates();
            this.getFoods();
          },
        },
      ],
    });

    await alert.present();
  }

  viewCart() {
    // console.log("viewCart");
    this.setData();
    //this.navCtrl.navigateRoot(['tabs/tab3']);
    this.router.navigate(["cart"]);
  }

  truncate(str, n) {
    // // console.log("este es el str", str);

    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  async presentPopover(ev: any) {
    if (this.dummyCategories.length <= 0) {
      return false;
    }
    const popover = await this.popoverController.create({
      component: MenuComponent,
      event: ev,
      componentProps: { data: this.dummyCategories },
      mode: "ios",
    });
    popover.onDidDismiss().then((data) => {
      // console.log(data.data);
      if (data && data.data) {
        const yOffset = document.getElementById(data.data.id).offsetTop;
        const yHOffset = document.getElementById(data.data.id).offsetHeight;

        // console.log(yOffset + " : " + yHOffset);
        this.content.scrollToPoint(0, yOffset, 1000);
      }
    });
    await popover.present();
  }

  scrollToCat(cat) {
    const yOffset = document.getElementById(cat.id).offsetTop;
    const yHOffset = document.getElementById(cat.id).offsetHeight;

    // console.log(yOffset + " : " + yHOffset);
    this.content.scrollToPoint(0, yOffset, 1000);
  }

  openDetails() {
    const navData: NavigationExtras = {
      queryParams: {
        id: this.id,
        open: this.isOpen,
      },
    };
    this.router.navigate(["rest-details"], navData);
    FirebaseAnalytics.logEvent({
      name: 'evInteraction',
      params: {
        event_category: 'tooltip',
        event_action: 'click',
        event_label: 'info',
        value: "no_aplica",
        section: this.name
      }
    }).then(() => {
      console.log("Logged event: evInteraction");
    }).catch((error) => {
      console.error("Error logging event:", error);
    });
  }
  openOpinions(){
    const navData: NavigationExtras = {
      queryParams: {
        id: this.id,
        open: this.isOpen,
        opinions: true,
      },
    };
    this.router.navigate(["rest-details"], navData);
  }

  getCurrency() {
    return this.util.getCurrecySymbol();
  }
  getFormattedPrice(price) {
    const formattedPrice = price.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return formattedPrice
  }

  doRefresh(event) {
    // console.log("Begin async operation");

    this.getVenueDetails();

    setTimeout(() => {
      // console.log("Async operation has ended");
      event.target.complete();
    }, 2000);
  }
}
