import { Component, OnDestroy, NgZone } from '@angular/core';
//import { Plugins } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  Geolocation,

} from "@ionic-native/geolocation/ngx";

import { Platform, NavController, ModalController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationExtras } from '@angular/router';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import OneSignal from 'onesignal-cordova-plugin';
import Swal from 'sweetalert2';

import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
//const { SplashScreen } = Plugins;
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { AnimationOptions } from '@ionic/angular/providers/nav-controller';
import firebase from 'firebase';
import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import { ApisService } from './services/apis.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

// import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
// import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { UtilService } from 'src/app/services/util.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { TrackerPage } from './pages/tracker/tracker.page';
import { CategoryPage } from './pages/category/category.page';
import { CouponsPage } from './pages/coupons/coupons.page';
import { App, URLOpenListenerEvent } from '@capacitor/app';

import * as amplitude from '@amplitude/analytics-browser';
import { RestaurantsPage } from './pages/restaurants/restaurants.page';

declare var google: any;

import { Facebook } from '@ionic-native/facebook/ngx'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  id: any;
  myaddress: [] = [];
  lat: any = -34.60967526810574;
  lng: any = -58.390570052673375;
  nearAddress: any;
  address: any;
  deeplink: any = false;
  deeplink_info: any;
  appVersion: string;

  constructor(
    private util: UtilService,
    private platform: Platform,
    private statusBar: StatusBar,
    private router: Router,
    // private oneSignal: OneSignal,
    private translate: TranslateService,
    public geolocation: Geolocation,
    private firebaseDynamicLinks: FirebaseDynamicLinks,
    private navCtrl: NavController,
    private modalController: ModalController,
    private firebaseConfig: FirebaseConfig,
    private diagnostic: Diagnostic,
    private api: ApisService,
    private androidPermissions: AndroidPermissions,
    private deeplinks: Deeplinks,
    private zone: NgZone,
    private fb: Facebook

  ) {
    console.log("La primer funcion , esta ejecuta InitalizeApp")
    // console.log("Appcomponent file  99999999999999")

    //Language Configuration
    const lng = localStorage.getItem('language');
    if (!lng || lng === null) {
      localStorage.setItem('language', 'spanish');
    }
    this.translate.use(localStorage.getItem('language'));


    this.initializeApp();

  }


  extractInfoFromLink(url) {
    console.log("extractInfoFromLink")
    // console.log("Entered the function with the link:", url);

    let order_id, venue_id, coupon, category_id;
    const category = "/category/";
    const tracker = "/tracker/";
    const coupons = "/coupons/";
    const restaurants = "/restaurants/"
    const separator = /[&?]/;

    const paramsArray = url.split(separator);

    if (url.includes(category)) {
      // console.log("Includes category", category);
      const param = paramsArray.find(param => param.includes(category));
      if (param) {
        venue_id = param.split('=')[1];
        venue_id = venue_id.split('/').pop()
        // console.log("Extracted venue_id:", venue_id);
      } else {
        // console.log("Category parameter not found.");
      }
    }

    if (url.includes(coupons)) {
      // console.log("Includes coupon", coupons);
      const param = paramsArray.find(param => param.includes(coupons));
      if (param) {
        coupon = param.split('=')[1];
        coupon = coupon.split('/').pop()

        // console.log("Extracted coupon:", coupon);
        localStorage.setItem('coupon', coupon);
      } else {
        // console.log("Coupon parameter not found.");
      }
    }

    if (url.includes(tracker)) {
      // console.log("Includes tracker / order_id", tracker);
      const param = paramsArray.find(param => param.includes(tracker));
      if (param) {
        order_id = param.split('=')[1];
        order_id = order_id.split('/').pop();
        // console.log("Extracted order_id:", order_id);
      } else {
        // console.log("Tracker parameter not found.");
      }
    }
    if (url.includes(restaurants)) {
      // console.log("Includes tracker / order_id", tracker);
      const param = paramsArray.find(param => param.includes(tracker));
      if (param) {
        category_id = param.split('=')[1];
        category_id = order_id.split('/').pop();
        // console.log("Extracted order_id:", order_id);
      } else {
        // console.log("Tracker parameter not found.");
      }
    }

    return { order_id, venue_id, coupon, category_id };
  }

  async getAppVersion() {
    try {

      this.api.getConfigVersion().then(async (res) => {
        const firebase_version_number = res.number;
        const required_version = res.required
        console.log("App Version firebase =====>:", firebase_version_number);

        const info = await App.getInfo(); // Use Capacitor's App plugin
        this.appVersion = info.version; // App version from info object

        if (required_version && firebase_version_number != this.appVersion) {
          this.util.show("Actualizar la aplicación")
          this.showUpdateAlert()
        }
        console.log('App Version =====>:', this.appVersion);
      }).catch((err) => {
        console.log("error", err)
      })
    } catch (error) {
      console.error('Error retrieving app version:', error);
    }
  }

  showUpdateAlert() {
    Swal.fire({
      title: 'Actualización disponible',
      text: 'Es necesario actualizar la aplicación para continuar.',
      icon: 'info',
      confirmButtonText: 'Actualizar',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("redirigime a actualizar la app")
        if (this.platform.is("android")) {
          window.open("https://play.google.com/store/apps/details?id=com.vgo.app");
        }
        if (this.platform.is("ios")) {
          window.open("https://apps.apple.com/app/1609468145");
        }

      }
    });
  }

  initializeApp() {
    // console.log("99999999999999999999 initialize app")
    this.getAppVersion();

    this.platform.ready().then(async () => {
      FirebaseAnalytics.initializeFirebase({
        apiKey: "AIzaSyCffqnZYz97NGi7tlIf-zJMt4dz9wr3G2Y",
        authDomain: "v-go-app.firebaseapp.com",
        projectId: "v-go-app",
        storageBucket: "v-go-app.appspot.com",
        messagingSenderId: "40194382803",
        appId: "1:40194382803:web:243f216fb81cf1b9756d26",
        measurementId: "G-BTPWKH83MF",
        databaseURL: "https://v-go-app.firebaseio.com",
      })
      FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
      });


      FirebaseAnalytics.setScreenName({
        screenName: "open_app_fa_test",
        nameOverride: "open_app_fa_test",
      });

      if (this.platform.is("android")) {
        FirebaseAnalytics.logEvent({
        name: "open_app_fa_android",
        params: {
          content_type: "image",
          content_id: "P12453",
          items: [{ name: "Kittens" }],
        },
      });
      }
      if (this.platform.is("ios")) {
        FirebaseAnalytics.logEvent({
        name: "open_app_fa_ios",
        params: {
          content_type: "image",
          content_id: "P12453",
          items: [{ name: "Kittens" }],
        },
      });
      }

      FirebaseAnalytics.logEvent({
        name: "open_app_fa_test",
        params: {
          content_type: "image",
          content_id: "P12453",
          items: [{ name: "Kittens" }],
        },
      });

      amplitude.init('85a0d6f890fd0690423aee64e9f76829', {
        defaultTracking: {
          sessions: true,
        }
      });
      console.log("activando facebook")
      this.fb.activateApp()
      this.fb.setApplicationId("277130288780784")
      this.fb.logEvent('test_event_code', "hola")

      let user = localStorage.getItem("uid")
      if (user) {
        amplitude.setDeviceId(user);
      }

      amplitude.track('OPEN APP');
     


      //Deeplinks Configuration
      this.extractDeeplinkInfo()

      setTimeout(async () => {
        this.OneSignalInit()
      }, 5000);



      //this.statusBar.backgroundColorByHexString('#FFF');
      await SplashScreen.hide();

      this.firebaseDynamicLinks.onDynamicLink()
        .subscribe((res: any) => console.log("dynamic", res), (error: any) => console.log("error dynamic", error));
      this.backButton();

      if (!this.platform.is('capacitor')) {
        this.initializeFirebaseApp();
        firebase.remoteConfig().fetchAndActivate();
      } else {
        this.firebaseConfig.fetchAndActivate();
      }

    });
  }


  initializeFirebaseApp() {
    firebase.initializeApp(environment.firebase);
  }

  deeplinkReRoute() {
    const navData: NavigationExtras = {
      queryParams: {
        type: this.deeplink.info,
        id: this.deeplink.id,
      },
    };
    if (this.deeplink && this.deeplink.info != "coupon") {
      this.router.navigate([`/${this.deeplink.info}`], navData);
    }

  }
  extractDeeplinkInfo() {

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // console.log("se abrio el deeplink, es este? ===>", event.url);
        const { order_id, venue_id, coupon, category_id } = this.extractInfoFromLink(event.url)
        // console.log("a ver si extrajo algo esto", order_id, venue_id, coupon)
        if (order_id) {

          // Navigate to the '/tracker' route with query parameters
          this.deeplink = {
            deeplink: "tracker",
            id: order_id
          }
          const navData: NavigationExtras = {
            queryParams: {
              id: order_id,
            },
          };
          this.router.navigate([`/tracker`], navData);

        } else if (venue_id) {

          this.deeplink = {
            deeplink: "category",
            id: venue_id
          }

          const navData: NavigationExtras = {
            queryParams: {
              id: venue_id,
            },
          };
          this.router.navigate(["category"], navData);

        } else if (coupon) {
          this.deeplink = {
            deeplink: "coupon",
            coupon: coupon
          }
          localStorage.setItem('coupon', coupon);

          //Add a coupon to the user
          //If its logged, add it . 
          //If its not logged, wait for the user to log in and add the coupon.
          let user = localStorage.getItem("uid")
          if (!user) {
            this.router.navigate(["login"]);
            this.util.errorToast(
              this.util.translate("In order to continue we need you to log in.")
            );
            return
          } else {
            this.api.addCoupon(user, { code: coupon }).then(data => {

              this.router.navigate(["coupons"]);
            }).catch(error => {
              this.router.navigate(["coupons"]);

              // console.log(error);
              this.util.errorToast(error);

            });
          }
        } else if (category_id) {

          this.deeplink = {
            deeplink: "restaurants",
            id: category_id
          }

          const navData: NavigationExtras = {
            queryParams: {
              type: category_id,
            },
          };
          this.router.navigate(["restaurants"], navData);

        }
      });
    });
    // console.log("aca deberia estar el deeplink de ios")
    this.deeplinks.route({
      '/tracker/:order_id': TrackerPage,
      '/category/:venue_id': CategoryPage,
      '/coupons/:coupon_id': CouponsPage,
      '/restaurants/:category_id': RestaurantsPage,

    }).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      // console.log('Successfully matched route deeplink ios?', match);

      // Access the order_id parameter from match.$args
      const order_id = match.$args.order_id;
      const venue_id = match.$args.venue_id;
      const coupon = match.$args.coupon_id;
      const category_id = match.$args.category_id;
      // Create navigation extras with query parameters
      if (order_id) {
        // console.log("order_id hay", order_id);
        this.deeplink = {
          deeplink: "tracker",
          id: order_id
        }
        const navData: NavigationExtras = {
          queryParams: {
            id: order_id,
          },
        };
        this.router.navigate([`/tracker`], navData);


        // Navigate to the '/tracker' route with query parameters

      } else if (venue_id) {
        // console.log("venue_id hay", venue_id);
        this.deeplink = {
          deeplink: "category",
          id: venue_id
        }
        const navData: NavigationExtras = {
          queryParams: {
            id: venue_id,
          },
        };
        this.router.navigate(["category"], navData);
      } else if (coupon) {
        // console.log("coupon hay", coupon);
        localStorage.setItem('coupon', coupon);

        //Add a coupon to the user
        //If its logged, add it . 
        //If its not logged, wait for the user to log in and add the coupon.
        // this.deeplink = {
        //   deeplink: "coupon",
        //   coupon: coupon
        // }
        let user = localStorage.getItem("uid")
        if (!user) {
          const navData = {
            queryParams: {
              deeplink: "coupon",
              coupon: coupon
            },
          };
          this.util.errorToast(
            this.util.translate("In order to continue we need you to log in.")
          );
          this.router.navigate(["login"], navData);
        } else {
          this.api.addCoupon(user, { code: coupon }).then(data => {
            this.util.showToast(`Se agregado el cupon ${coupon} con exito!`, 'success', 'bottom');
          }).catch(error => {
            // console.log(error);
            this.util.errorToast(error);
          });
        }
      } else if (category_id) {

        this.deeplink = {
          deeplink: "restaurants",
          id: category_id
        }

        const navData: NavigationExtras = {
          queryParams: {
            type: category_id,
          },
        };
        this.router.navigate(["restaurants"], navData);

      }

    }, nomatch => {
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });


    //Oboarding Configuration
    this.onboardingConfig()


  }



  onboardingConfig() {

    let navData;

    if (this.deeplink) {
      navData = {
        queryParams: {
          deeplink: this.deeplink,
        },
      };
    }
    //Checking if the user has ever used the app Onboarding Phase
    const onboarding = localStorage.getItem('onboarding');
    const diet_type = localStorage.getItem('diet_type');
    console.log("se fija si hay onboarding y diet type", onboarding, diet_type)
    if (!onboarding) {
      //Never used the app with the phone
      localStorage.setItem('hasSeenOverlay', 'true');

      this.router.navigate(["onboarding"], navData);
      
    } else {
      if (!diet_type) {
        console.log("no tiene diet_type")
        if (localStorage.getItem("uid")) {

          this.api.getMyProfile(localStorage.getItem("uid")).then((data: any) => {
            if (!data.diet_type) {
              this.router.navigate(["diet-type"], navData);
            } else {
              localStorage.setItem('diet_type', data.diet_type);
            }
          })
        } else {
          this.router.navigate(["login"], navData);
        }

      } else {
        const date: any = localStorage.getItem('lastSignIn')
        const dateNow = Date.now()
        const hours = Math.abs(date - dateNow) / 36e5;

        //Opened app in the last 5 hours
        if (hours <= 5) {
          // console.log("ENTRO HACE MENOS DE 5 HORAS", hours)
          const address = localStorage.getItem("deliveryAddress")
          if (address) { //If it has an address assigned
          } else { // Si no tiene direccion ejecuta la funcion siguiente()
            // console.log("NO Address assigned")
            this.siguiente(navData)
          }
          localStorage.setItem('lastSignIn', Date.now().toString())
        } else {
          //Entro hace mas de 5 horas
          // console.log('ENTRO HACE MAS DE 5 HORAS', hours)
          this.siguiente(navData)
        }
      }

    }
  }
  OneSignalInit() {

    // console.log("Onesignal information");
    // console.log('- appid', environment.onesignal.appId);
    // console.log('- googlenumnner', environment.onesignal.googleProjectNumber);
    try {

      console.log("empieza one signal config")

      // Uncomment to set OneSignal device logging to VERBOSE  
      OneSignal.setLogLevel(6, 0);


      // NOTE: Update the setAppId value below with your OneSignal AppId.
      OneSignal.setAppId(environment.onesignal.appId);
      OneSignal.setLaunchURLsInApp(false)
      OneSignal.setNotificationOpenedHandler(function (jsonData) {
        // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      });

      console.log("se viene la config de onesignal uwu")

      OneSignal.getDeviceState(info => {
        console.log("el device state de onesignal", JSON.stringify(info))
        console.log("el device state de onesignal2", info)
        console.log("el id del device state", info.userId)
        let fcm_token = info.userId
        console.log("el email del device state", info.emailAddress)
        console.log("el subscribed del device state", info.subscribed)

        let fcm = localStorage.getItem('fcm')
        console.log("hay fcm?", fcm, fcm_token)

        if (!fcm && fcm_token) {
          localStorage.setItem('fcm', fcm_token)
          let user_uid = localStorage.getItem("uid")
          if (user_uid) {
            this.api.getMyProfile(user_uid).then((data: any) => {
              if (data.fcm_token) {
                if (Array.isArray(data.fcm_token)) {
                  data.fcm_token.push(fcm_token);
                } else {
                  // If data.fcm_token is not an array, set it to an array containing info.userId
                  data.fcm_token = [fcm_token];
                }
              } else {
                // If data.fcm_token is undefined or null, initialize it as an array containing info.userId
                data.fcm_token = [fcm_token];
              }
              console.log("el fcm que voy a guardar en el perfil:", data.fcm_token)
              let param = {
                uid: user_uid,
                fcm_token: data.fcm_token
              };

              this.api.updateProfile(user_uid, param);

            })

          }
        }
      })
      OneSignal.getTags(info => {
        console.log("los tags de onesignal", info)
      })



      // Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
      OneSignal.promptForPushNotificationsWithUserResponse()
      Notification.requestPermission(notificationResponse => {
        // console.log("Permitio las notis?" , notificationResponse);
      })




    } catch (error) {
      console.log("Onesignal Error", error);

    }
  }


  siguiente(deeplink_info: any) {
    console.log("en siguiente");
    const userLogged = localStorage.getItem("uid")

    //SI ESTA LOGEADO, PREGUNTO SI TIENE DIRECCIONES
    if (userLogged) {
      console.log("esta logueado")
      this.id = userLogged
      this.directionsOne(deeplink_info)

      FirebaseAnalytics.setUserId({
        userId: userLogged
      }).then(() => {
        console.log("User logged :", userLogged);
      }).catch((error) => {
        console.error("Error logging event:", error);
      });

    } else {
      console.log("no esta logueado")
      this.router.navigate(["login"], deeplink_info);
      // SI NO ESTA LOGEADO MOSTRAR POP UP DE -- CONTANOS DONDE IR -- PARA AGREGAR DIRECCION
    }
  }


  async directionsOne(deeplink_info) {
    // console.log("directions one fuckkkkkkkk")

    await this.getAddresses()
    // console.log(this.myaddress)

    if (this.myaddress.length) {
      //SI TIENE DIRECCIONES VA A CHEQUEAR SI TIENE LA UBICACION AUTORIZADA Y ACTIVADA
      this.locationOne()


    } else {
      //SI NO HAY DIRECCIONES DEBE REDIRIGIR DIRECTAMENTE A ANADIR DIRECCION 
      this.router.navigate(["direction-onboarding"], deeplink_info);
      // console.log("no hay dires9")
    }

  }


  //FUNCION Q SE EJECUTA CUANDO EL USUARIO Q NO ENTRO POR PRIMERA VEZ, ESTA LOGEADO, NO TIENE DELIVERY ADDRESS Y TIENE DIRECCIONES
  locationOne() {
    this.getLocation()
  }
  requestAndroidLocation() {
    this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
  }
  dismiss() { }

  async getLocation() {
    // console.log("por preguntar por location")
    this.platform.ready().then(async () => {

      if (this.platform.is('android')) {
        // console.log("platform = android")
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
          result => {
            // console.log('Has permission?', result.hasPermission)
            if (!result.hasPermission) {
              // this.util.showCustomAlert("Activa tu ubicación", "Para un mejor funcionamiento <3", "./assets/imgs/logopng-Negro.png", this.dismiss, this.requestAndroidLocation )
              this.requestAndroidLocation()
            }
          },

          async err => {
            // console.log("androidPermissions", err)
            this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
            // this.router.navigate(['home']);
          }
        );
        this.grantRequest();
      } else if (this.platform.is('ios')) {
        // console.log("platform = ios")
        this.grantRequest();
      } else {
        // console.log("platform = none");

        await this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 10000, enableHighAccuracy: false }).then((resp) => {
          if (resp) {
            // console.log('resp', resp);
            this.lat = resp.coords.latitude;
            this.lng = resp.coords.longitude;
            // this.loadmap(resp.coords.latitude, resp.coords.longitude, this.mapEle);


            //FUNCION QUE EJECUTO PASANDOLE LAS COORDENADAS DE getCurrentLocation PARA CONSEGUIR LA DIRECCION LEGIBLE
            // this.getCurrentAddress(this.lat, this.lng);
            this.getDistanceBetween(resp.coords.latitude, resp.coords.longitude)

          }
        });
      }
    });















    // this.diagnostic.isLocationAuthorized().then((res: any) => // console.log("location authorized? ", res)).catch((err: any) => // console.log("error location authorized ", err))

    // await this.diagnostic.isLocationAvailable()
    //   .then((res: any) => {
    //     // console.log("isLocationAvailable ", res)
    //     if (!res) {
    //       this.diagnostic.switchToLocationSettings()
    //     }
    //   }).
    //   catch((err) => // console.log("isLocationAvailable", err))
    // await this.diagnostic.isLocationEnabled().then(async (data) => {
    //   if (data) {
    //     // console.log("location enabled ", data)
    //     await this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 20000, enableHighAccuracy: false }).then((resp) => {
    //       if (resp) {
    //         // console.log('resp', resp);
    //         // this.loadmap(resp.coords.latitude, resp.coords.longitude, this.mapEle);
    //         // this.getAddress(resp.coords.latitude, resp.coords.longitude);
    //       }
    //     })
    //       .catch((err) => // console.log("error en el primer getCurrentPosition", err));
    //   } else {
    //     // console.log("not enabled, swtichToLocationSettings");

    //     this.diagnostic.switchToLocationSettings();
    //     await this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 20000, enableHighAccuracy: false }).then((resp) => {
    //       if (resp) {
    //         // console.log('ress,', resp);
    //         // this.loadmap(resp.coords.latitude, resp.coords.longitude, this.mapEle);
    //         // this.getAddress(resp.coords.latitude, resp.coords.longitude);
    //       }
    //     })
    //       .catch((err) => // console.log("error en el segundo getCurrentPosition", err));
    //   }
    // }).catch((err) => // console.log("error en location enabled"))



  }


  grantRequest() {
    console.log("Entra a gran request")

    this.diagnostic.isLocationEnabled().then((data) => {
      // console.log("isLocationEnabled = ", data)
      if (data) {
        this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 10000, enableHighAccuracy: false }).then((resp) => {
          if (resp) {
            // console.log('ressGetCurrentPosition1', resp);
            // this.getCurrentAddress(resp.coords.latitude, resp.coords.longitude);
            this.getDistanceBetween(resp.coords.latitude, resp.coords.longitude)
          }
        }).catch((err) => console.log("ressGetCurrentPosition1", err))
      } else {
        this.diagnostic.switchToLocationSettings();
        this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 10000, enableHighAccuracy: false }).then((resp) => {
          if (resp) {
            // console.log('ressGetCurrentPosition2,', resp);
            // this.getCurrentAddress(resp.coords.latitude, resp.coords.longitude);
            this.getDistanceBetween(resp.coords.latitude, resp.coords.longitude)
          } else {


            (<any>this.router).navigate([`choose-address`], {
              queryParams: {
                hasDirections: true,
              }
            })

          }
        }).catch((err) => console.log("ressGetCurrentPosition2", err));
      }
    }, error => {
      // console.log('errir', error);
    }).catch(error => {
      // console.log('error', error);
    });

  }


  getCurrentAddress(lat, lng) {
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lng);
    geocoder.geocode({ 'location': location }, (results: any, status: any) => {
      // console.log('status ' + status + results);
      this.address = results[0].formatted_address;
      this.lat = lat;
      this.lng = lng;
    });
  }


  async getDistanceBetween(lat, lng) {
    if (this.myaddress.length) {
      this.myaddress.forEach((c: any) => {

        const km = this.distance(c.lat, c.lng, lat, lng)

        // SI ESTA A MENOS DE 0.3KM
        if (km < 0.400) {

          if (this.nearAddress) {
            //SI LA nearAddress ANTERIOR ESTA MAS LEJOS (ES MAYOR) QUE LA QUE SE ESTA ITERANDO
            if (this.nearAddress.near > km) {
              c.near = km
              this.nearAddress = c
            }

          } else {
            c.near = km
            this.nearAddress = c
          }

        }
      })
      if (this.nearAddress) {
        //MOSTRAR POP UP DE CONFIRMAR DIRECCION

        this.util.showCustomAlert("¡Confirmanos tu dirección!", this.nearAddress.address.split(',')[0], "./assets/imgs/logopng-Negro.png", this.cancelLastDirection.bind(this), this.confirmLastDirection.bind(this))

      } else {
        this.router.navigate([`direction-onboarding`], {
          queryParams: {
            hasDirections: true,
          }
        })
      }
    }
  }




  distance(lat1, lon1, lat2, lon2) {
    let p = 0.017453292519943295;    // Math.PI / 180
    let c = Math.cos;
    let a = 0.5 - c((lat2 - lat1) * p) / 2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p)) / 2;


    const km = 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    return km
  }





  confirmLastDirection() {
    // console.log("confirmLastDirection", this.nearAddress)
    this.address = this.nearAddress
    localStorage.setItem('deliveryAddress', JSON.stringify(this.nearAddress))
    localStorage.setItem('lastSignIn', JSON.stringify(Date.now()))

    return
  }
  cancelLastDirection() {
    // console.log("cancelLastDirection")
    if (this.deeplink.info) {
      // console.log("The", this.deeplink)
      this.router.navigate(["choose-address"], {
        queryParams: {
          deeplink: this.deeplink,
        },
      });
    } else {
      this.router.navigate(["choose-address"], {
        queryParams: {
          from: "home",
        },
      });
    }


  }





  async getAddresses() {
    await this.api.getMyAddress(this.id)
      .then((data) => {
        // console.log("ADRESSSS", data)
        if (data.length) {
          this.myaddress = data;
          // this.calcAddressDistance();
        }

      })
      .catch(error => {
        // console.log(error);
      });
  }






  backButton() {
    this.platform.backButton.subscribeWithPriority(10, async (processNextHandler) => {
      const modal = await this.modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
      processNextHandler();
    });

    this.platform.backButton.subscribeWithPriority(-1, (processNextHandler) => {
      if (this.router.url.includes('/tabs')) {
        navigator['app'].exitApp();
      } else {
        let animations: AnimationOptions = {
          animated: true,
          animationDirection: "back"
        }
        this.navCtrl.back(animations);
      }
      processNextHandler();
    });
  }
}







/** this.diagnostic.isLocationAuthorized().then((res) => // console.log("ISLOCATIONAUTHORIZED", res)).catch((err) => // console.log("ISLOCATIONAUTHORIZED", err))
        this.diagnostic
          .isLocationEnabled()
          .then(
            (data) => {
              if (data) {
                this.geolocation
                  .getCurrentPosition({
                    maximumAge: 3000,
                    timeout: 10000,
                    enableHighAccuracy: false,
                  })
                  .then((resp) => {
                    if (resp) {
                      //// console.log('resp', resp);
                     // this.lat = resp.coords.latitude;
               // this.lng = resp.coords.longitude;
                      // this.getAddress(resp.coords.latitude, resp.coords.longitude);

                    }
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              } else {
                // this.diagnostic.switchToLocationSettings()
                this.geolocation
                  .getCurrentPosition({
                    maximumAge: 3000,
                    timeout: 10000,
                    enableHighAccuracy: false,
                  })
                  .then((resp) => {
                    if (resp) {
                      //// console.log('ress,', resp);
                     // this.lat = resp.coords.latitude;
              //  this.lng = resp.coords.longitude;
                      // this.getAddress(resp.coords.latitude, resp.coords.longitude);
                    }
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              }
            },
            (error) => {
              // console.log("errorLOCATION", error);
              // this.dummy = [];
            }
          )
          .catch((error) => {
            // console.log("errorLOCATION", error);
            // this.dummy = [];
          });
           */