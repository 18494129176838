import { UtilService } from 'src/app/services/util.service';
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { ApisService } from "src/app/services/apis.service";
import Swal from 'sweetalert2';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
declare const gtag: Function;

@Component({
  selector: 'app-variations',
  templateUrl: './variations.page.html',
  styleUrls: ['./variations.page.scss'],
})
export class VariationsPage implements OnInit {
  productName: any = '';
  desc: any = '';
  total: any = 0;
  newTotal: any = 0;
  amount_of_radios_selected: any = 0;
  cover: any;
  covers: any[] = [];

  scheduleToday: any;
  lists: any;
  cart: any[] = [];
  userCart: any[] = [];

  sameProduct: boolean = false;
  removeProduct: boolean = false;

  radioSelected: any;
  haveSize: boolean;
  food: any;
  alwaysFalse: boolean = false;
  alwaysTrue: boolean = true;
  newItem: boolean = false;
  vegan_activated: boolean = false;
  slideOpts = {
    initialSlide: 0,
    speed: 150,
    autoplay: true

  };
  phone: any;
  sameCart: any[] = [];
  constructor(
    private modalController: ModalController,
    private navParma: NavParams,
    private util: UtilService,
    private router: Router,
    private api: ApisService,
    private alertController: AlertController,

  ) {

    const info = this.navParma.get('food');
    const phone = this.navParma.get('phone');
    const scheduleToday = this.navParma.get('scheduleToday');
    const vegan_activated = this.navParma.get('vegan_mode');
    console.log("vegan_mode", vegan_activated)
    this.vegan_activated = vegan_activated;
    this.food = info;
    console.log('info', info);
    

    const item = {
      item_id: info.id,
      item_name: info.name,
      quantity: info.quantiy,
      price: info.price,
     };
    
    // Create the event data
    const viewItemData = {
      currency: 'ARS',
      items: [item],
    };
    
    // Log the view_item event
    FirebaseAnalytics.logEvent({
      name: 'view_item',
      params: {
        currency: viewItemData.currency,
        items: viewItemData.items,
      },
    }).then(() => {
      console.log('Logged event: view_item');
    }).catch((error) => {
      console.error('Error logging view_item event:', error);
    });

    
    this.productName = info.name;
    this.cover = info.cover
    this.desc = info.desc;
    this.scheduleToday = scheduleToday;
    // console.log("la fecha de hoy", this.scheduleToday);
    // console.log("the variations", info.product_variations);
    this.covers = info.covers ? info.covers : []
    this.lists = info.product_variations ? JSON.parse(JSON.stringify(info.product_variations)) : []
    this.lists.forEach((item, i) => {
      if(this.vegan_activated){
        console.log('los items', item.items)
        this.lists[i].items = item.items.filter(sub => sub.status && sub.vegan);
      }else{
        this.lists[i].items = item.items.filter(sub => sub.status);
      }
    });
    this.lists = this.lists.filter(list => list.items.length > 0)
    // console.log("LISTS!", this.lists);

    const userCart = localStorage.getItem('userCart');
    this.haveSize = info.size;
    this.total = info.price;
    this.newTotal = info.price;
    // console.log('usercart---->', userCart);
    if (userCart && userCart !== 'null' && userCart !== undefined && userCart !== 'undefined') {
      this.userCart = JSON.parse(userCart);
      // console.log('===>>', this.userCart);
      const sameItem = this.userCart.filter(x => x.id === info.id);
      // console.log('sameItem', sameItem);
      if (sameItem.length > 0) {
        this.sameProduct = true;
        this.sameCart = sameItem[0].selectedItem;
        // console.log('=??==>asdasd-->>>asd>>>>', this.sameCart);
      }
    } else {
      this.userCart = [];
    }
  }
  change_url(input_url) {
    // Define the part to be replaced and the replacement
    const partToReplace = 'https://firebasestorage.googleapis.com/v0/b/v-go-app.appspot.com/o/';
    const replacement = 'http://34.117.68.250/';

    // Perform the replacement
    const modified_url = input_url.replace(partToReplace, replacement);

    // Return the modified URL
    return modified_url;
  }

  ngOnInit() {
  }
  closeIt() {
    this.modalController.dismiss();
  }

  radioGroupChange(event, title) {
    console.log(event, title);
    // console.log(this.lists);
    const radioList = this.lists.filter(x => x.title === title);
    // console.log(radioList);

    const selectedItems = radioList[0].items.filter(x => x.title === event.detail.value);
    console.log('selected item', selectedItems);

    const price = parseFloat(selectedItems[0].price);
    const param = {
      type: title,
      value: price,
      name: selectedItems[0].title,
      vegan: selectedItems[0].vegan ? selectedItems[0].vegan : false
    };

    // console.log("este es el param", param);

    this.cart = this.cart.filter(x => x.type != title);
    // console.log("el cart1", this.cart);

    this.cart.push(param);
    // console.log("el cart2", this.cart);


    // if (item && item.length) {
    //   const index = this.cart.findIndex(x => x.type === title);
    //   this.cart[index].value = price;
    // } else {
    //   this.cart.push(param);
    // }
    this.newTotal = this.total + price;
    // console.log("new total radio", this.newTotal);

    // console.log('cart', this.cart);
    // console.log('ng model', this.radioSelected);
  }


  getSymobol() {
    return this.util.getCurrecySymbol();
  }
  sameChoise() {
    this.modalController.dismiss(this.sameCart, 'sameChoice');
  }

  getFormattedPrice(price) {
    const formattedPrice = price.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    // console.log(formattedPrice)
    return formattedPrice
  }

  addToCart() {
    /*
      new
      sameChoice
      newCustom
      remove
    */
    const vid = localStorage.getItem("vid");
    // console.log("id", vid, this.food.uid);
    if (vid && vid !== this.food.uid) {
      this.presentAlertConfirm();
      return false;
    }
   

    const item = {
      item_id: this.food.id.id,
      item_name: this.food.name,
      quantity: 1,
      price: this.food.price,
     };
  
  // Create the event data
  const addToCartData = {
    currency: 'ARS',
    items: [item],
  };
  
  // Log the add_to_cart event
  FirebaseAnalytics.logEvent({
    name: 'add_to_cart',
    params: {
      currency: addToCartData.currency,
      items: addToCartData.items,
    },
  }).then(() => {
    console.log('Logged event: add_to_cart');
  }).catch((error) => {
    console.error('Error logging add_to_cart event:', error);
  });
    if (this.food.christmas && this.food.christmas_scheduled) {
      Swal.fire({
        title: "Agregar al carrito",
        text: "La entrega de este producto es durante el día 24 de diciembre",
        icon: 'question',
        confirmButtonText: "Agregar",
        backdrop: false,
        background: 'white',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "No"
      }).then(data => {
        // console.log(data);
        if (data && data.value) {
          const addedSize = this.cart.filter(x => x.type === 'size');
          // console.log("addedSize", addedSize);
          // console.log("this.cart", this.cart);
          // console.log("this.userCart!", this.userCart);
          let role;
          let radio = false
          let check = false
          this.lists.forEach(item => {
            if (item.type == 'radio') {
              radio = true
            } else if (item.type == 'check') {
              check = true
            }
          });
          let noEmpty = true
          if (radio || check) {
            noEmpty = false
          }
          // console.log("a ver igual", this.cart.length, this.lists.length);

          if (!this.cart.length && this.lists.length > 0) {
            // console.log("a ver", this.cart.length, this.lists.length);

            this.util.errorToast('Selecciona una opción');
            return false;
          }
          if (this.cart.length && !this.userCart.length) {
            role = 'new';
          }
          if (this.cart.length && this.userCart.length) {
            role = 'new';
          }
          if (!this.lists.length && !this.cart.length) {
            role = 'new';
          } else if (!this.cart.length) {
            role = 'dismissed';
          }
          if (this.newItem) {
            role = 'newCustom';
          }
          this.modalController.dismiss(this.cart, role);

        } else {
          this.closeIt()
        }
      });
    } else {

      const addedSize = this.cart.filter(x => x.type === 'size');
      // console.log("addedSize", addedSize);
      // console.log("this.cart", this.cart);
      // console.log("this.userCart!", this.userCart);
      let role;
      let radio = false
      let check = false
      let amount_of_radios = []
      this.lists.forEach(item => {
        // console.log("los items son", item)
        if (item.type == 'radio') {
          radio = true
          amount_of_radios.push('radio')
        } else if (item.type == 'check') {
          check = true
        }
      });
      // console.log("a ver igual", this.cart.length, this.lists.length, amount_of_radios);

      let noEmpty = true
      if (radio || check) {
        noEmpty = false
      }
      if (!this.cart.length && this.lists.length > 0) {
        // console.log("a ver", this.cart.length, this.lists.length);

        this.util.errorToast('Selecciona una opción');
        return false;
      }

      if (this.cart.length && !this.userCart.length) {
        role = 'new';
      }
      if (this.cart.length && this.userCart.length) {
        role = 'new';
      }
      if (!this.lists.length && !this.cart.length) {
        role = 'new';
      } else if (!this.cart.length) {
        role = 'dismissed';
      }
      if (this.newItem) {
        role = 'newCustom';
      }

      console.log("this.cart", this.cart);
      console.log("this.userCart!", this.userCart);
      if (this.cart.some(element => !element.vegan)) {
        this.modalController.dismiss(this.cart, role);
      } else {
        this.modalController.dismiss(this.cart, role);

      }
    }
  }

  checkedEvent(event, title, type, vegan) {
    // console.log(event, title);
    const price = parseFloat(event.detail.value);

    const param = {
      type: type,
      value: price,
      name: title,
      vegan: vegan
    };

    console.log("check event param", param);

    if (event.detail && event.detail.checked) {
      this.total += price;
      // console.log(" total", this.total);

      this.cart.push(param);
      this.newTotal += price;
      // console.log("new total", this.newTotal);

    } else {
      this.total -= price;
      // console.log(" total", this.total);
      this.cart = this.cart.filter(x => x.name !== title);
      this.newTotal -= price;
      // console.log("new total", this.newTotal);

    }
    // console.log(this.cart);

  }

  addQ(index) {
    const vid = localStorage.getItem("vid");
    // console.log("id", vid, this.food.uid);
    if (vid && vid !== this.food.uid) {
      this.presentAlertConfirm();
      return false;
    }
    const [food]: any = this.userCart.filter(x => x.id === this.food.id);
    food.quantiy = food.quantiy + 1;
    this.sameCart[index].total = this.sameCart[index].total + 1;
  }

  removeQ(index) {
    const [food]: any = this.userCart.filter(x => x.id === this.food.id);


    if (food && food.quantiy !== 0) {
      food.quantiy = food.quantiy - 1;
      if (food.quantiy === 0) {
        this.modalController.dismiss(this.cart, 'remove');
      }
    }
    this.sameCart[index].total = this.sameCart[index].total - 1;
    if (this.sameCart[index].total === 0) {
      this.sameCart = this.sameCart.filter(x => x.total !== 0);
    }

    if (this.sameCart.length < 0) {
      this.modalController.dismiss(this.cart, 'remove');
    }
  }

  addQVariation(item, indexVariation, indexItem, vegan) {
    const vid = localStorage.getItem("vid");
    // console.log("id", vid, this.food.uid);
    if (vid && vid !== this.food.uid) {
      this.presentAlertConfirm();
      return false;
    }
    const newQuantity = item.quantity + 1;
    this.lists[indexVariation].items[indexItem].quantity = newQuantity
    /*// console.log(quantity, price, title);
    const newQuantity = quantity + 1;
    const total = parseFloat(price) * newQuantity;
    const param = {
      type: title,
      value: total,
      name: title,
      quantity: newQuantity
    };
    if (newQuantity > 0) {
      this.cart.push(param);
    } else {
      this.cart = this.cart.filter(x => x.type !== title);
    }
    // console.log(this.cart);*/

    const price = parseFloat(item.price);
    const total = price * newQuantity;
    this.total += total;

    const param = {
      type: this.lists[indexVariation].title,
      value: price,
      name: item.title,
      vegan: vegan,
      quantity: newQuantity
    };

    if (newQuantity === 1) {
      this.cart.push(param);
    } else {
      const variationItem = this.cart.findIndex(v => v.name === item.title)
      if (variationItem > -1) {
        this.cart[variationItem] = param
      } else {
        this.cart.push(param)
      }
    }

    this.newTotal = this.total;
    // console.log(this.cart);
  }
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: this.util.translate("Warning"),
      message: this.util.translate(
        `you already have item's in cart with different restaurant`
      ),
      buttons: [
        {
          text: this.util.translate("Cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            // console.log("Confirm Cancel: blah");
            this.closeIt();
          },
        },
        {
          text: this.util.translate("Clear cart"),
          handler: () => {
            // console.log("Confirm Okay");
            localStorage.removeItem("vid");
            localStorage.removeItem("categories");
            localStorage.removeItem("dummyItem");
            localStorage.removeItem("foods");
            this.closeIt();

          },
        },
      ],
    });
    await alert.present();

  }

  removeQVariation(item, indexVariation, indexItem, vegan) {
    if (this.lists[indexVariation].items[indexItem].quantity == 0) {
      return false
    }

    const newQuantity = item.quantity - 1;
    this.lists[indexVariation].items[indexItem].quantity = newQuantity;
    const price = parseFloat(item.price);
    const total = price * newQuantity;
    const param = {
      type: this.lists[indexVariation].title,
      value: price,
      name: item.title,
      quantity: newQuantity,
      vegan: vegan
    };
    this.total -= total;
    this.newTotal = this.total;

    if (newQuantity == 0) {
      this.cart = this.cart.filter(x => x.name !== item.title);
      // console.log("CART0", this.cart);

    } else {
      const variationItem = this.cart.findIndex(v => v.name === item.title)
      if (variationItem > -1) {
        this.cart[variationItem] = param
      }
    }
    // console.log("Este es el cart: ", this.cart);
  }

  countItems(v) {
    return this.lists[v].items.reduce((total, item) => {
      return total + item.quantity;
    }, 0)
  }

  getCurrency() {
    return this.util.getCurrecySymbol();
  }

  isValid() {
    const countVariations = this.lists.filter(variation => variation.type === 'count')
    let isValid = true;

    countVariations.forEach((element, index) => {
      let totalItems = 0;
      element.items.forEach(item => {
        totalItems += item.quantity
      });
      if (element.min && totalItems < parseInt(element.min)) {
        isValid = false;
        return isValid;
      }
    });
    if (this.scheduleToday == "Hoy no abre") {
      isValid = false
      return isValid
    }
    const addedSize = this.cart.filter(x => x.type === 'size');

    if (this.haveSize && !addedSize.length) {
      isValid = false;
      return isValid;
    }

    return isValid;
  }
}
