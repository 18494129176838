import { Injectable } from '@angular/core';
import { Platform, LoadingController, AlertController, ToastController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { duration } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  loader: any;
  isLoading = false;

  details: any;
  localControl: any = "asdfnasd"


  // lat: any = -34.60967526810574;
  // lng: any = -58.390570052673375;
  // control: boolean;




  private address = new Subject<any>();
  private coupon = new Subject<any>();
  private review = new Subject<any>();
  orders: any;

  private changeLocation = new Subject<any>();

  private loggedIn = new Subject<any>();

  private profile = new Subject<any>();
  constructor(
    private platform: Platform,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private toastCtrl: ToastController,
    public router: Router,
    private navCtrl: NavController,
    private translateService: TranslateService,
    private androidPermissions: AndroidPermissions,
    public geolocation: Geolocation,
    private diagnostic: Diagnostic,




  ) {
  }
  /*
  Start Loader
  Call this method to Start your Loader
  */

  publishAddress(data: any) {
    this.address.next(data);
  }

  publishReview(data: any) {
    this.review.next(data);
  }

  publishProfile(data: any) {
    this.profile.next(data);
  }

  observProfile(): Subject<any> {
    return this.profile;
  }
  getLanguage() {
    return this.translateService.currentLang;
  }

  getReviewObservable(): Subject<any> {
    return this.review;
  }

  publishLocation(data) {
    this.changeLocation.next(data);
  }
  subscribeLocation(): Subject<any> {
    return this.changeLocation;
  }

  publishLoggedIn(data) {
    this.loggedIn.next(data);
  }
  subscribeLoggedIn(): Subject<any> {
    return this.loggedIn;
  }
  translate(str) {
    const currentLang = this.translateService.currentLang;
    const returnValue = this.translateService.translations[currentLang][str];
    if (returnValue === undefined) {
      return this.translateService.translations.en_merch[str];
    } else {
      return returnValue;
    }
  }
  translateInEN(str) {
    const currentLang = 'spanish';
    const returnValue = this.translateService.translations[currentLang][str];
    if (returnValue === undefined) {
      return this.translateService.translations.en_merch[str];
    } else {
      return returnValue;
    }
  }

  getCurrencyCode() {
    return environment.general.code;
  }

  getCurrecySymbol() {
    return environment.general.symbol;
  }

  getAddress(): Subject<any> {
    return this.address;
  }

  publishCoupon(data: any) {
    this.coupon.next(data);
  }
  getCouponObservable(): Subject<any> {
    return this.coupon;
  }

  setOrders(data) {
    this.orders = null;
    this.orders = data;
  }

  gerOrder() {
    return this.orders;
  }
  async show(msg?) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: msg,
      spinner: 'bubbles',
    }).then(a => {
      a.present().then(() => {
        //// console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() =>  console.log('abort presenting'));
        }
      });
    });
  }

  async hide() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }

  /*
    Show Warning Alert Message
    param : msg = message to display
    Call this method to show Warning Alert,
    */
  async showSuccessAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Success',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }
  async showCustomAlert(title, subtitle, msg, cancelFn, confirmFn) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: subtitle,
      // message: msg,
      message: `<img src="${msg}" alt="g-maps" style="border-radius: 2px">`,

      cssClass: 'custom-alert',
      backdropDismiss: false,
      buttons:
        this.platform.is("ios") ? [
          {
            role: 'confirm',
            text: 'Confirmar',
            cssClass: 'alert-button-ok',
            handler: data => {
              confirmFn(data)

            }

          }, {
            role: 'cancel',
            text: 'Cambiar',
            cssClass: 'alert-button-directions',
            handler: data => {
              // this.router.navigate(["choose-address"], {});
              cancelFn()
            }

          }] : [{
            role: 'cancel',
            text: 'Cambiar',
            cssClass: 'alert-button-directions',
            handler: data => {
              // this.router.navigate(["choose-address"], {});
              cancelFn()
            }

          }, {
            role: 'confirm',
            text: 'Confirmar',
            cssClass: 'alert-button-ok',
            handler: data => {
              // SE HACE LO QUE SE QUIERA HACER EN LA FUNCION PASADA COMO ARG
              confirmFn(data)

            }
          }],
    });

    await alert.present();
  }
  async showCustomAlertConfirmOnly(title, subtitle, msg,confirmText, confirmFn) {
    const alert = await this.alertCtrl.create({
      header: title,
      // subHeader: subtitle,
      // message: msg,
      message: `<img src="${msg}" alt="g-maps" style="border-radius: 2px">`,

      cssClass: 'custom-alert',
      backdropDismiss: false,
      buttons:
        this.platform.is("ios") ? [
          {
            role: 'confirm',
            text: confirmText,
            cssClass: 'alert-button-ok',
            handler: data => {
              confirmFn(data)

            }
          }] : [{
            role: 'confirm',
            text: confirmText,
            cssClass: 'alert-button-ok',
            handler: data => {
              // SE HACE LO QUE SE QUIERA HACER EN LA FUNCION PASADA COMO ARG
              confirmFn(data)

            }
          }],
    });

    await alert.present();
  }
  async showCustomAlertConfirmOnlyWithCheckbox(title, subtitle, msg, checkbox_text, confirmFn, status?: any) {
    let checkboxStatus = true; // Default value
    const alert = await this.alertCtrl.create({
      header: title,
      message: `<img src="${msg}" alt="g-maps" style="border-radius: 2px">`,
      cssClass: 'custom-alert',
      backdropDismiss: false,
      inputs: [
        {
          type: 'checkbox',
          name: 'check',
          label: checkbox_text,
          checked: true,
          id: 'input-checkbox',
          handler: (info) => {
            checkboxStatus = info.checked; // Update checkbox status
          }
        },
      ],
      buttons: this.platform.is("ios") ? [
        {
          role: 'confirm',
          text: 'Confirmar',
          cssClass: 'alert-button-ok',
          handler: data => {
            confirmFn(data, checkboxStatus); // Pass checkbox status to confirmation function
          }
        }
      ] : [
        {
          role: 'confirm',
          text: 'Confirmar',
          cssClass: 'alert-button-ok',
          handler: data => {
            confirmFn(data, checkboxStatus); // Pass checkbox status to confirmation function
          }
        }
      ],
    });
  

    await alert.present();
  }
  async showCustomImageAlertConfirmOnly(title, image, confirmFn) {
    const alert = await this.alertCtrl.create({
      header: title,
      // subHeader: subtitle,
      // message: msg,
      message: `<div class="image-container">
       <img src="${image}" alt="g-maps" class='first_order' style="">    
      </div>
    `,

      cssClass: 'custom-alert-image',
      backdropDismiss: true,
      buttons:
        this.platform.is("ios") ? [
          {
            role: 'confirm',
            text: '¡Quiero mi cupón!',
            cssClass: 'alert-button-ok',
            handler: data => {
              confirmFn(data)

            }
          }] : [{
            role: 'confirm',
            text: '¡Quiero mi cupón!',
            cssClass: 'alert-button-ok',
            handler: data => {
              // SE HACE LO QUE SE QUIERA HACER EN LA FUNCION PASADA COMO ARG
              confirmFn(data)

            }
          }],
    });

    await alert.present();
  }
  async showAddAddress(title, subtitle) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: subtitle,
      // message: msg,
      message: `<img src="./assets/imgs/logopng-Negro.png" alt="g-maps" style="border-radius: 2px">`,

      cssClass: 'custom-alert',
      backdropDismiss: false,
      buttons:
        [
          {
            role: 'confirm',
            text: 'Confirmar',
          }]
    });

    await alert.present();
  }
  async showWarningAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Warning',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  async showSimpleAlert(msg, title = "") {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  /*
   Show Error Alert Message
   param : msg = message to display
   Call this method to show Error Alert,
   */
  async showErrorAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  /*
     param : email = email to verify
     Call this method to get verify email
     */
  async getEmailFilter(email) {
    const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if (!(emailfilter.test(email))) {
      const alert = await this.alertCtrl.create({
        header: 'Warning',
        message: 'Please enter valid email',
        buttons: ['OK']
      });
      await alert.present();
      return false;
    } else {
      return true;
    }
  }


  /*
    Show Toast Message on Screen
     param : msg = message to display, color= background 
     color of toast example dark,danger,light. position  = position of message example top,bottom
     Call this method to show toast message
     */

  async showToast(msg, colors, positon, duration: number = 2000) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: duration,
      color: colors,
      position: positon
    });
    toast.present();
  }
  async showNotification(msg, colors, positon) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      color: colors,
      position: positon,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            // // console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  async errorToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }
  async notificationSuccess(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color: "success"
    });
    toast.present();
  }

  apiErrorHandler(err) {
    // // console.log('Error got in service =>', err)
    if (err.status === -1) {
      this.showErrorAlert('Failed To Connect With Server');
    } else if (err.status === 401) {
      this.showErrorAlert('Unauthorized Request!');
      this.navCtrl.navigateRoot('/login');
    } else if (err.status === 500) {
      this.showErrorAlert('Somethimg Went Wrong..');
    }
  }

  // setDetails(data) {
  //   this.details = null;
  //   this.details = data;
  // }
  // getDetails() {
  //   return this.details;
  // }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  scheduleIsOpen(schedule) {

    // console.log("este es el schedule ==>" , schedule);
    
    let diaHoy = new Date().getDay();
    let horaHoy = new Date().getHours();
    let minutosHoy = new Date().getMinutes();

    if (diaHoy == 0) {
      diaHoy = 6;
    } else if (diaHoy == 1) {
      diaHoy = 0;
    } else if (diaHoy == 2) {
      diaHoy = 1;
    } else if (diaHoy == 3) {
      diaHoy = 2;
    } else if (diaHoy == 4) {
      diaHoy = 3;
    } else if (diaHoy == 5) {
      diaHoy = 4;
    } else if (diaHoy == 6) {
      diaHoy = 5;
    }


    const scheduleToday = schedule[diaHoy];

    // console.log("SCHEDULE TODAY", scheduleToday);

    let horario0Hoy = {
      hor0open: 0,
      hor0close: 0,
      min0open: 0,
      min0close: 0,
    };

    let horario1Hoy = {
      hor1open: 0,
      hor1close: 0,
      min1open: 0,
      min1close: 0,
    };

    if (scheduleToday[0].open != "") {
      // console.log("scheduleToday[0]", scheduleToday[0]);

      horario0Hoy = {
        hor0open: parseInt(scheduleToday[0].open.split(":")[0]),
        hor0close: parseInt(scheduleToday[0].close.split(":")[0]),
        min0open: parseInt(scheduleToday[0].open.split(":")[1]),
        min0close: parseInt(scheduleToday[0].close.split(":")[1]),
      };
    }

    if (scheduleToday[1] != "") {
      // console.log("scheduleToday[1]", scheduleToday[1]);

      horario1Hoy = {
        hor1open: parseInt(scheduleToday[1].open.split(":")[0]),
        hor1close: parseInt(scheduleToday[1].close.split(":")[0]),
        min1open: parseInt(scheduleToday[1].open.split(":")[1]),
        min1close: parseInt(scheduleToday[1].close.split(":")[1]),
      };
    }

    // console.log("SCHEDULE TODAY", horario0Hoy, horario1Hoy);
    if (
      (horaHoy >= horario0Hoy.hor0open  && horaHoy <= horario0Hoy.hor0close) ||
         (horaHoy >= horario1Hoy.hor1open && horaHoy <= horario1Hoy.hor1close )
        //Aca ya esta dentro de la hora, ahora hay que ver minutos
    ){
      if (
        (horario0Hoy.hor0open == horaHoy ||
          horario1Hoy.hor1open == horaHoy) &&
        (horario0Hoy.min0open <= minutosHoy ||
          horario1Hoy.min1open <= minutosHoy)
      ) {
        
        return true;
      } else if (
        (horario0Hoy.hor0close == horaHoy ||
          horario1Hoy.hor1close == horaHoy) &&
        (horario0Hoy.min0close >= minutosHoy ||
          horario1Hoy.min1close >= minutosHoy)
      ) {
       
        return true;
      } else if (
        horario0Hoy.hor0open == horaHoy ||
        horario1Hoy.hor1open == horaHoy ||
        horario0Hoy.hor0close == horaHoy ||
        horario1Hoy.hor1close == horaHoy
      ) {
        return false;
      } else {
        
        return true;
      }
    } else {
      return false;
    }


    // const scheduleToday0 =
    //   scheduleToday[0].open + " - " + scheduleToday[0].close;
    // const scheduleToday1 =
    //   scheduleToday[1].open + " - " + scheduleToday[1].close;

    // if (!current) {
    //   this.scheduleToday = "Hoy no abre";
    // } else {
    //   if (scheduleToday[0].open != "" && scheduleToday[1].open != "") {
    //     this.scheduleToday = scheduleToday0 + " y " + scheduleToday1;
    //   } else if (scheduleToday[0].open != "") {
    //     this.scheduleToday = scheduleToday0;
    //   } else if (scheduleToday[1].open != "") {
    //     this.scheduleToday = scheduleToday1;
    //   } else {
    //     this.scheduleToday = "Hoy no abre";
    //   }
    // }
    // // console.log("schedule!", this.scheduleToday);
  }

  makeOrderId() {
    /*const now = new Date()
    let timestamp = now.getFullYear().toString().substr(2,2); // 21
    timestamp += (now.getMonth() < 9 ? '0' : '') + now.getMonth().toString(); // JS months are 0-based, so +1 and pad with 0's
    timestamp += ((now.getDate() < 10) ? '0' : '') + now.getDate().toString(); // pad with a 0
    timestamp += ((now.getHours() < 10) ? '0' : '') + now.getHours().toString(); // pad with a 0
    timestamp += ((now.getMinutes() < 10) ? '0' : '') + now.getMinutes().toString(); // pad with a 0
    timestamp += ((now.getSeconds() < 10) ? '0' : '') + now.getSeconds().toString(); // pad with a 0
    timestamp += ((now.getMilliseconds() < 10) ? '0' : '') + now.getMilliseconds().toString(); // pad with a 0

    return timestamp*/


    return new Date().valueOf().toString();

  }








  async none() {
    // console.log("funcionaaa")
  }


  /* ---------------------- FLUJO UBICACION -------------------------- */





  async getLocation(localThis) {
    // console.log("por preguntar por location")
    // // console.log(localThis.control, localThis.lat, localThis.lng)
    // // console.log(this);
    // localThis.control = true

    // localThis.util.none()
    // await localThis.util.showCustomAlert("Necesitamos tu ubicacion", "Queres activarla", "./assets/imgs/logopng-Negro.png", localThis.util.cancel, localThis.util.confirm.bind(this, localThis))


    // localThis = true
    // this.lat = 1
    this.platform.ready().then(async () => {
      if (this.platform.is('android')) {
        // console.log("platform = android")
        await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
          async result => {
            // console.log('Has permission?', result.hasPermission, result)
            if (!result.hasPermission) {
              await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
                .then((async res => {
                  // console.log("acepto la ubicacion???", res.hasPermission, res)
                  if (res.hasPermission) await localThis.util.grantRequest.apply(this, [this])
                })
                )
            }
          },
        );

      } else if (this.platform.is('ios')) {
        // console.log("platform = ios")
        await localThis.util.grantRequest.apply(this, [this])
      } else {
        // console.log("platform = none");

        await this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 10000, enableHighAccuracy: false }).then((resp) => {
          if (resp) {
            // console.log('resp getCurrentPosition on getLocation()', resp);
            localThis.control = true
            localThis.lat = resp.coords.latitude;
            localThis.lng = resp.coords.longitude;
            // this.loadmap(resp.coords.latitude, resp.coords.longitude, this.mapEle);


            //FUNCION QUE EJECUTO PASANDOLE LAS COORDENADAS DE getCurrentLocation PARA CONSEGUIR LA DIRECCION LEGIBLE
            // this.getCurrentAddress(this.lat, this.lng);
            // this.getDistanceBetween(resp.coords.latitude, resp.coords.longitude)

          }
        });
      }
    });
  }

  async grantRequest(localThis) {
    await this.diagnostic.isLocationEnabled().then(async (data) => {
      // console.log("isLocationEnabledddd = ", data)
      if (data) {
        this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 10000, enableHighAccuracy: false }).then((resp) => {
          if (resp) {
            // console.log('ressGetCurrentPosition1 1 1', resp);
            // console.log(resp.coords.latitude, resp.coords.longitude)
            localThis.control = true
            localThis.lat = resp.coords.latitude
            localThis.lng = resp.coords.longitude
            // this.getCurrentAddress(resp.coords.latitude, resp.coords.longitude);
            // this.getDistanceBetween(resp.coords.latitude, resp.coords.longitude)
          }
        }).catch((err) =>  console.log("err ressGetCurrentPosition111 1 1", JSON.stringify(err)))
      } else {


        await localThis.util.showCustomAlert("Necesitamos tu ubicacion", "Queres activarla", "./assets/imgs/logopng-Negro.png", localThis.util.cancel, localThis.util.confirm.bind(this, localThis))
        // this.diagnostic.switchToLocationSettings();
        // this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 10000, enableHighAccuracy: false }).then((resp) => {
        // if (resp) {
        // // console.log('ressGetCurrentPosition2,', resp);
        // this.getCurrentAddress(resp.coords.latitude, resp.coords.longitude);
        // this.getDistanceBetween(resp.coords.latitude, resp.coords.longitude)
        // }
        // }).catch((err) => // console.log("err ressGetCurrentPosition2", err));
        // console.log("NO ACEPTO LA HABILITACION DE UBICACION1234")
      }
    }, error => {
      // console.log('errir', error);
    }).catch(error => {
      // console.log('error getcurrentposition', error);
    });

  }

  cancel() {
    // console.log("no quiere ir a la config")
    // this.util.hide()
  }
  async confirm(localThis) {
    // console.log("quiere ir a la config123123")

    // this.control = true
    // // console.log(this.control, this.lat)
    // this.lat = 2342423
    // console.log(localThis, localThis.control);


    // setTimeout(() => {

    this.diagnostic.switchToLocationSettings()
    // // }, 1000);
    await this.geolocation.getCurrentPosition({ maximumAge: 3000, timeout: 15000, enableHighAccuracy: false }).then((resp) => {
      if (resp) {
        // // console.log('ressGetCurrentPosition2,', resp);
        // console.log("RESHANDLECONFIRM", resp.coords.latitude, resp.coords.longitude)
        localThis.control = true
        localThis.lat = resp.coords.latitude
        localThis.lng = resp.coords.longitude
        //     // this.getCurrentAddress(resp.coords.latitude, resp.coords.longitude);
        //     // this.getDistanceBetween(resp.coords.latitude, resp.coords.longitude)
      }
    }).catch((err) =>  console.log("err ressGetCurrentPosition2", err));



  }


}