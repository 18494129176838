
import { NgModule } from '@angular/core';
import { HidenavModule } from './hidenav.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FirebaseConfigService } from '../services/firebase-config.service';

@NgModule({
    imports: [HidenavModule, CommonModule],
    exports: [HidenavModule, TranslateModule],
    providers: [FirebaseConfigService]
})
export class SharedModule { }
