import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject, from, Observable } from "rxjs";
//import firebase from "firebase/app";
import firebase from "firebase/app";
import { Router, NavigationExtras } from '@angular/router';

import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import moment from "moment";
import { map } from "rxjs/operators";
//import { cfaSignIn, cfaSignOut, mapUserToUserInfo, cfaSignInPhone } from 'capacitor-firebase-auth';
import OneSignal from 'onesignal-cordova-plugin';

import { Platform } from "@ionic/angular";

export class AuthInfo {
  constructor(public $uid: string) {}

  isLoggedIn() {
    return !!this.$uid;
  }
}

@Injectable({
  providedIn: "root",
})
export class ApisService {
  static UNKNOWN_USER = new AuthInfo(null);
  db = firebase.firestore();
  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(
    ApisService.UNKNOWN_USER
  );
  //api = 'https://us-central1-v-go-app.cloudfunctions.net/api/v1'
  // api = 'http://localhost:5001/v-go-app/us-central1/api/v1'
  api = environment.apiUrl;
  constructor(
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore,
    private http: HttpClient,
    public platform: Platform,
    private router: Router,

  ) {}

  public checkAuth() {
    // console.log("corre checkauth");
    
    return new Promise((resolve, reject) => {
      this.fireAuth.onAuthStateChanged(async (user) => {
        // console.log("user ==>", user);
        if (user) {
          localStorage.setItem("uid", user.uid);
          let data = await this.getMyProfile(user.uid);
          if (!data) {
            await this.createUser(user);
            const uid = user.uid;
            data = await this.getMyProfile(uid);
          } else if (!data.email) {
            await this.createUser(user, data);
            const uid = user.uid;
            data = await this.getMyProfile(uid);
          }

          resolve({
            ...user,
            profile: data,
          });
        } else {
          // console.log("deslogueandox?");
          
          this.logout();
          const lng = localStorage.getItem("language");
          const selectedCity = localStorage.getItem("selectedCity");
          const date = localStorage.getItem("lastSignIn");
          const deliveryAddress = localStorage.getItem("deliveryAddress")
          const userCart = localStorage.getItem("userCart")
          const vid = localStorage.getItem("vid")
          const coupon = localStorage.getItem("coupon")

          localStorage.clear();

          localStorage.setItem('onboarding', 'true')
          localStorage.setItem('lastSignIn', date)
          localStorage.setItem('userCart', userCart)
          if(vid){
            localStorage.setItem('vid', vid)
          }
          if(deliveryAddress){
            localStorage.setItem("deliveryAddress", deliveryAddress);
          }
          if(coupon){
            localStorage.setItem("coupon", coupon);
          }
          localStorage.setItem("language", lng);
          localStorage.setItem("selectedCity", selectedCity);
          resolve(false);
        }
      });
    });
  }

  public login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          if (res.user) {
            // console.log("este es el usuario que llega al login", res.user);
            
            // this.db
            //   .collection("users")
            //   .doc(res.user.uid)
            //   .update({
            //     fcm_token: localStorage.getItem("fcm")
            //       ? localStorage.getItem("fcm")
            //       : "",
            //   });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch((err) => {
          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  /*socialSignIn({providerName, scopes, phoneNumber} : {providerName: string, scopes?: Array<string>, phoneNumber?: string}): Observable<any> {
    if (this.platform.is('capacitor')) {
      if(providerName === 'phone') {
        return cfaSignInPhone(phoneNumber);
      }
      return cfaSignIn(providerName);
    } else {
      const provider = new auth.OAuthProvider(providerName);

      if (scopes) {
        scopes.forEach(scope => {
          provider.addScope(scope);
        });
      }

      if (this.platform.is('desktop')) {
        return from(this.fireAuth.auth.signInWithPopup(provider));
      } else {
        // web but not desktop, for example mobile PWA
        return from(this.fireAuth.auth.signInWithRedirect(provider));
      }
    }
  }

  public signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider();
    return this.socialSignIn({providerName: provider.providerId});
  }

  public signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const scopes = ['profile', 'email'];
    return this.socialSignIn({providerName: provider.providerId, scopes});
  }*/

  public getCities(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("cities", (ref) => ref.where("status", "==", 'active'))
        .get()
        .subscribe(
          (venue: any) => {
            let data = venue.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public register(
    email: string,
    password: string,
    fullname: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          if (res.user) {
            this.db
              .collection("users")
              .doc(res.user.uid)
              .set({
                uid: res.user.uid,
                email: email,
                fullname: fullname,
                type: "user",
                status: "active",
                fcm_token: localStorage.getItem("fcm")
                  ? localStorage.getItem("fcm")
                  : "",
                created_at: new Date(),
                orders:0,
              });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
              // OneSignal.sendTags({
              //   "registered": 1,
              //   "onboarding": 0
              // });
          }
        })
        .catch((err) => {
          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  createUser(result, originalUser = null) {
    const data = this.setUserModelForProfile(result);
    if (originalUser) {
    // console.log("este es el mail de facebook original", originalUser.email," hay mailll?")

      Object.assign(data, {
        firstName: originalUser.firstName || data.firstName,
        lastName: originalUser.lastName || data.lastName,
        fullname: originalUser.fullname || data.fullname,
        email: originalUser.email || data.email,
        phoneNumber: originalUser.phoneNumber || data.phoneNumber,
        image: originalUser.image || data.image,
        providerId: originalUser.providerId || data.providerId,
      });
     
    }
    // console.log("este es el mail de facebook", data.email," hay mailll?")
    const newUser = {
      uid: data.uid,
      firstName: data.firstName,
      lastName: data.lastName,
      fullname: data.fullname,
      email: data.email,
      phone: data.phoneNumber,
      avatar: data.image,
      provider: data.providerId,
      type: "user",
      status: "active",
      fcm_token: localStorage.getItem("fcm") ? [localStorage.getItem("fcm")] : [],
      created_at: new Date(),
    };
    
      // Check if OneSignal is available before calling its methods
      if (typeof OneSignal !== "undefined" && OneSignal) {
        try {
          OneSignal.setExternalUserId(data.uid);
          OneSignal.setEmail(data.email);
        } catch (error) {
          console.error("Error setting OneSignal data:", error);
        }
      } else {
        console.warn("OneSignal is not available in this environment.");
      }
    return this.updateUser(newUser);
  }

  private setUserModelForProfile(data) {
    // console.log("DATA!------->", data);
    // console.log("additionalUserInfo!------->", data.additionalUserInfo);

    const profile = data.additionalUserInfo
      ? data.additionalUserInfo.profile
      : data;
    const providerId = data.additionalUserInfo
      ? data.additionalUserInfo.providerId
      : data.providerData[0].providerId;
    const profileProvider =
      data.providerData && data.providerData.length ? data.providerData[0] : {};

    const userModel = {
      // isNewUser: data.additionalUserInfo.isNewUser,
      uid: data.user ? data.user.uid : data.uid,
      firstName: profile.first_name || profile.given_name || profileProvider.displayName || profileProvider.givenName || null,
      lastName: profile.last_name || profile.family_name || profileProvider.displayName || profileProvider.familyName ||  null,
      fullname: profile.name || profile.displayName || profileProvider.displayName || null,
      image: this.getPhotoURL(providerId, profile) || null,
      phoneNumber: profile.phoneNumber || profileProvider.phoneNumber || null,
      email: profile.email || profileProvider.email || null,
      providerId: providerId || profileProvider.providerId || null,
    };

    // console.log("userModel!------->", userModel);
    return userModel;
  }

  getPhotoURL(signInProviderId: string, profile: any): string {
    // Default imgs are too small and our app needs a bigger image
    switch (signInProviderId) {
      case "facebook.com":
        return profile.picture && profile.picture.data
          ? profile.picture.data.url + "?height=400"
          : profile.photoURL + "?height=400";
      case "password":
        return "https://s3-us-west-2.amazonaws.com/ionicthemes/otros/avatar-placeholder.png";
      case "twitter.com":
        return profile.photoURL.replace("_normal", "_400x400");
      case "google.com":
        return profile.picture || profile.photoURL.split("=")[0];
      //return profile.photoURL.split('=')[0];
      default:
        return profile.photoURL;
    }
  }

  updateUser(userData): Promise<void> {
    return this.db
      .collection("users")
      .doc(userData.uid)
      .set(userData, { merge: true });
  }


  public cabifyError(param, responseError): Promise<any> {
    let newParam ={
      cabifyError: JSON.stringify(responseError),
      errorDate: new Date
    }
    // console.log("lo que llega a cabifyError",  param, newParam);
    return new Promise<any>(async (resolve, reject) => {
      this.adb
      .collection("cabifyError")
      .doc()
      .set(newParam)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public mpError(param, responseError): Promise<any> {
    // console.log("lo que llega a mperror",  responseError);
    let newParam = {
      ...param,
      mpError: JSON.stringify(responseError),
      errorDate: new Date
    }
    return new Promise<any>(async (resolve, reject) => {
      this.adb
      .collection("mpError")
      .doc(param.orderId)
      .set(newParam)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public async setFcmNotification(uid, tokenNotification ): Promise<any> {
    await this.getProfile(uid).then(userInfo => {

      // console.log("lo que llega a setFcmNotification ",  userInfo, tokenNotification );
      let newParam = {
      } 
      if(Array.isArray(userInfo.fcm_token)){
        // console.log("es array",userInfo.fcm_token);
        
        if(!userInfo.fcm_token.includes(tokenNotification)){
        // console.log("el token  no es parte del array",userInfo.fcm_token , userInfo.fcm_token);

          //fcm_token es una lista pero no incluye al nuevo token (nuevo dispositivo) entonces lo agrega
          let nuevaLista = userInfo.fcm_token.push(tokenNotification)
          // console.log("la nueva lista", nuevaLista);
          newParam = {
            fcm_token : userInfo.fcm_token? userInfo.fcm_token : ""
          } 
          
        }else{
          // ya lo tenia al dispositivo en el user de firebase
          // console.log("el token  SI es parte del array",userInfo.fcm_token , userInfo.fcm_token);

            newParam = {
              fcm_token : userInfo.fcm_token? userInfo.fcm_token : ""
            } 
        }
        
      }else {
        if (userInfo.fcm_token && userInfo.fcm_token.length > 0) {
        // console.log("el token no es array pero su longitud es > 0 ",userInfo.fcm_token , userInfo.fcm_token);

          if (userInfo.fcm_token == tokenNotification) {
          // console.log("el token  == al que ya tengo en fcm_token",userInfo.fcm_token , userInfo.fcm_token);
          
          userInfo.fcm_token =  [userInfo.fcm_token] 

          newParam = {
            fcm_token : userInfo.fcm_token? userInfo.fcm_token : ""
          } 
          
        }else{
           // console.log("el token  no es igual al fcm_token",userInfo.fcm_token , userInfo.fcm_token);

           userInfo.fcm_token =  [userInfo.fcm_token, tokenNotification] 
           newParam = {
            fcm_token : userInfo.fcm_token? userInfo.fcm_token : ""
          } 
          }

        }else{
          // console.log("el token no es array y tampoco tiene la info ",userInfo.fcm_token , userInfo.fcm_token);

          userInfo.fcm_token =  [tokenNotification] 
          newParam = {
            fcm_token : userInfo.fcm_token ? userInfo.fcm_token : ""
          } 
        }
      }
      // console.log("el new param: " , newParam, uid);
      
      return new Promise<any>(async (resolve, reject) => {
        this.adb
        .collection("users")
        .doc(uid)
        .set(userInfo)
          .then(async (order: any) => {
            resolve(order);
          })
          .catch((error) => {
            reject(error);
          });
      });

    })

   
    
  }
  public changeAllUsers(): Promise<any> {
      return new Promise<any>(async (resolve, reject) => {
       
       
      });

    

   
    
  }
  public resetPassword(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .sendPasswordResetEmail(email)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(`reset failed ${err}`);
        });
    });
  }

  public logout(): Promise<void> {
    this.authInfo$.next(ApisService.UNKNOWN_USER);
    // this.db.collection('users').doc(localStorage.getItem('uid')).update({ "fcm_token": firebase.firestore.FieldValue.delete() })
    return this.fireAuth.signOut();
  }

  public getProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .get()
        .subscribe(
          (profile: any) => {
            resolve(profile.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getCity(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("cities")
        .doc(id)
        .get()
        .subscribe(
          (profile: any) => {
            resolve(profile.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getConfigVersion(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("config")
        .doc('version')
        .get()
        .subscribe(
          (profile: any) => {
            resolve(profile.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getCustomerServicePhone(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("config")
        .doc('customer_service_phone')
        .get()
        .subscribe(
          (profile: any) => {
            resolve(profile.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenues(args?) {
    // console.log("Trayendo locales", args);
    
    //return new Promise<any>((resolve, reject) => {
    //this.adb.collection('venue', ref => ref.where('type', '==', args.type)).get().subscribe((venue) => {
    /*this.adb.collection('venue', ref => ref.where('types', 'array-contains', args.type)).get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });*/
    return this.http
      .get<any>(
        `${this.api}/venues?type=${args.type}&lat=${args.lat}&lng=${args.lng}&cityId=${args.cityId}&diet_type=${args.diet_type}`
      )
      .pipe(
        map((data: any) => {
          
          // // console.log(data);
          return data;
        })
      );
    //});
  }
  public getVenuesByCoupon(args?) {
    //return new Promise<any>((resolve, reject) => {
    //this.adb.collection('venue', ref => ref.where('type', '==', args.type)).get().subscribe((venue) => {
    /*this.adb.collection('venue', ref => ref.where('types', 'array-contains', args.type)).get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });*/
    return this.http
      .get<any>(
        `${this.api}/venues/coupon?offer_id=${args.offer_id}&lat=${args.lat}&lng=${args.lng}&cityId=${args.cityId}`
      )
      .pipe(
        map((data: any) => {
          // // console.log(data);
          return data;
        })
      );
    //});
  }

  public getVenueDetails(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .doc(id)
        .get()
        .subscribe(
          (venue: any) => {
            resolve(venue.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getMyProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .get()
        .subscribe(
          (users: any) => {
            resolve(users.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenueUser(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .get()
        .subscribe(
          (venue: any) => {
            resolve(venue.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenueCategories(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("categories", (ref) =>
          ref.where("uid", "==", id).orderBy("order")
        )
        .get()
        .subscribe(
          (venue) => {
            var data = venue.docs.map((element) => {
              var item: any = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  
  public getFoods(uid: any, type:any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if(type == 'vegan'){
        this.db
        .collection("foods")
        .doc(uid)
        .collection("all")
        .get()
        .then(
          (data) => {
            var users = data.docs.map((doc) => {
              var item = doc.data();
              item.id = doc.id;
  
              // Fetch the related cid document
              item.cid.get().then(function (cidDoc) {
                item.cid = cidDoc.data();
                item.cid.id = cidDoc.id;
              });
  
              return item;
            });
  
            // Filter products: exclude those with isArchived: true or deleted: true and isArchived: true
            users = users.filter((item) => {
              // If isArchived field exists and is true, exclude the item
              if (item.isArchived === true) {
                return false;
              }
              if(item.veggie == true){
                return false
              }
              // If deleted is true and isArchived is true, exclude the item
              if (item.deleted === true && item.isArchived === true) {
                return false;
              }
  
              // Otherwise, include the item (this handles products without isArchived)
              return true;
            });
  
            // Return the filtered products
            resolve(users);
          },
          (error) => {
            reject(error);
          }
        );
      }else{
        this.db
        .collection("foods")
        .doc(uid)
        .collection("all")
        .get()
        .then(
          (data) => {
            var users = data.docs.map((doc) => {
              var item = doc.data();
              item.id = doc.id;
  
              // Fetch the related cid document
              item.cid.get().then(function (cidDoc) {
                item.cid = cidDoc.data();
                item.cid.id = cidDoc.id;
              });
  
              return item;
            });
  
            // Filter products: exclude those with isArchived: true or deleted: true and isArchived: true
            users = users.filter((item) => {
              // If isArchived field exists and is true, exclude the item
              if (item.isArchived === true) {
                return false;
              }
  
              // If deleted is true and isArchived is true, exclude the item
              if (item.deleted === true && item.isArchived === true) {
                return false;
              }
  
              // Otherwise, include the item (this handles products without isArchived)
              return true;
            });
  
            // Return the filtered products
            resolve(users);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }
  

  
  // public getFoodsByIds(uid: any,ids:any): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     this.db
  //       .collection("foods")
  //       .doc(uid)
  //       .collection("all")
  //       .where("id", "in", ids)
  //       .get()
  //       .then(
  //         (data) => {
  //           var users = data.docs.map((doc) => {
  //             var item = doc.data();
  //             item.cid.get().then(function (doc) {
  //               item.cid = doc.data();
  //               item.cid.id = doc.id;
  //             });
  //             item.id = doc.id;
  //             return item;
  //           });
  //           resolve(users);
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //   });
  // }
  
  public async getFoodsByIds(uid: string, ids: string[]): Promise<any> {
    if (!ids || ids.length === 0) {
      return [];
    }
  
    const batchSize = 10;
    const batches = [];
  
    // Break the IDs into chunks of 10
    for (let i = 0; i < ids.length; i += batchSize) {
      const batchIds = ids.slice(i, i + batchSize);
      batches.push(
        this.db
          .collection("foods")
          .doc(uid)
          .collection("all")
          .where("id", "in", batchIds)
          .get()
      );
    }
  
    // Execute all batch queries
    const results = await Promise.all(batches);
  
    // Merge results from all queries
    let foods = [];
    for (const data of results) {
      const batchFoods = data.docs.map((doc) => {
        let item = doc.data();
        item.id = doc.id;
        return item;
      });
      foods = foods.concat(batchFoods);
    }
  
    return foods;
  }
  
  public getMessages(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("messages")
        .doc(id)
        .collection("chats")
        .get()
        .subscribe(
          (messages: any) => {
            // console.log(messages);
            let data = messages.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getOfferByCode(code): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("offers", (ref) =>
          ref
            .where("code", "==", code.toUpperCase())
            .where("status", "==", "active")
            .where("private", "==", false)
        )
        .get()
        .subscribe(
          (venue: any) => {
            let data = venue.docs
              .filter((element) => {
                let item = element.data();
                item.id = element.id;
                const currentDate = moment().format("YYYY-MM-DD");
                return moment(item.expire).isAfter(currentDate);
              })
              .map((element) => {
                let item = element.data();
                item.id = element.id;
                return item;
              });
            resolve(data && data.length ? data[0] : null);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public goToVenue(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
     
      const navData: NavigationExtras = {
        queryParams: {
          id: id,
        },
      };     
      this.router.navigate(["category"], navData);
    });
  }

  public getOffers(uid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(uid)
        .collection("offers")
        .get()
        .subscribe(
          async (offers: any) => {
            var dataOffers = await offers.docs.map(async (offer) => {
              var item = await offer.data();
              await item.oid.get().then(async (doc) => {
                item.oid = await doc.data();
                item.oid.id = doc.id;
              });
              item.id = offer.id;
              return item;
            });

            const data = await Promise.all(dataOffers);

            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public addCoupon(uid, args): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const offer = await this.getOfferByCode(args.code);
      if (offer) {
        const params = {
          status: "active",
          oid: this.db.collection("offers").doc(offer.id),
        };

        const offerRef = await this.db
          .collection("users")
          .doc(uid)
          .collection("offers")
          .doc(offer.id);
        const existOffer = await offerRef.get();
        if (!existOffer.exists) {
          if (offer.firstOrder) {
            const orders = await this.getMyOrders(uid);
            if (
              orders &&
              orders.length &&
              orders.some((order) => order.status === "delivered")
            ) {
              reject("Este cupón es válido sólo para primera compra");
              return;
            }
          }
          const limit = parseInt(offer.limit);
          const applied = parseInt(offer.applied);
          if (limit && limit > 0 && applied >= limit) {
            reject("Este cupón ya está agotado");
            return;
          }
          this.adb
            .collection("users")
            .doc(uid)
            .collection("offers")
            .doc(offer.id)
            .set(params)
            .then(
              async (data) => {
                await this.db
                  .collection("offers")
                  .doc(offer.id)
                  .update({ applied: applied + 1 });
                resolve(data);
              },
              (error) => {
                reject(error);
              }
            )
            .catch((error) => {
              reject(error);
            });
        } else {
          reject("Ya tenés cargado ese cupón o ya lo usaste");
        }
      } else {
        reject("El código ingresado no está disponible");
      }

      localStorage.removeItem('coupon')

    });
  }

  public updateOffer(uid, oid, params: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(uid)
        .collection("offers")
        .doc(oid)
        .update(params)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public addNewAddress(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateAddress(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .doc(id)
        .update(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteAddress(uid, id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .doc(id)
        .delete()
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getMyAddress(uid: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("address")
        .doc(uid)
        .collection("all")
        .get()
        .then(
          (data) => {
            var users = data.docs.map((doc) => {
              var item = doc.data();
              item.id = doc.id;
              return item;
            });
            resolve(users);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // public createOrder(id, param): Promise<any> {
  //   // console.log("LLEGA EL ID:", id);
  //   param.vid = this.db.collection('venue').doc(param.vid);
  //   param.uid = this.db.collection('users').doc(param.uid);
  //   // param.dId = this.db.collection('users').doc(param.dId);
  //   return new Promise<any>((resolve, reject) => {
  //     this.adb.collection('orders').doc(id).set(param).then((data) => {
  //       resolve(data);
  //     }, error => {
  //       reject(error);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  public createPendingOrder(id, param): Promise<any> {
    // console.log("LLEGA EL ID:", id);
    // console.log("este es el param que le mando create pending order",param);
   
    param.vid = this.db.collection("venue").doc(param.vid);
    param.uid = this.db.collection("users").doc(param.uid);
   
    // param.dId = this.db.collection('users').doc(param.dId);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingOrders")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public createOrder(id, param): Promise<any> {
    // console.log("LLEGA EL ID:", id);
    // console.log("este es el param que le mando create order",param);
   
    param.vid = this.db.collection("venue").doc(param.vid);
    param.uid = this.db.collection("users").doc(param.uid);
   
    // param.dId = this.db.collection('users').doc(param.dId);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendNotification(msg, title, id) {
    const body = {
      app_id: environment.onesignal.appId,
      include_player_ids: [id],
      headings: { en: title },
      contents: { en: msg },
      data: { task: msg },
    };
    const header = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", `Basic ${environment.onesignal.restKey}`),
    };
    return this.http.post(
      "https://onesignal.com/api/v1/notifications",
      body,
      header
    );
  } 
  sendMail() {
    var message = { 
      app_id: environment.onesignal.appId,
      include_player_ids: ['ae42bf5c-ff2e-4df9-81d3-ddab3119255a'],      
      "email_subject": "Welcome to Cat Facts!",
      "email_body": "<html><head>Welcome to Cat Facts</head><body><h1>Welcome to Cat Facts<h1><h4>Learn more about everyone's favorite furry companions!</h4><hr/><p>Hi Nick,</p><p>Thanks for subscribing to Cat Facts! We can't wait to surprise you with funny details about your favorite animal.</p><h5>Today's Cat Fact (March 27)</h5><p>In tigers and tabbies, the middle of the tongue is covered in backward-pointing spines, used for breaking off and gripping meat.</p><a href='https://catfac.ts/welcome'>Show me more Cat Facts</a><hr/><p><small>(c) 2018 Cat Facts, inc</small></p><p><small><a href='[unsubscribe_url]'>Unsubscribe</a></small></p></body></html>"
    };
    const header = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", `Basic ${environment.onesignal.restKey}`),
    };
    return this.http.post(
      "https://onesignal.com/api/v1/notifications",
      message,
      header
    );
  } 

  sendNotificationVenue({body}) {
    
    return this.http
      // .post<any>(`http://localhost:5001/v-go-app/us-central1/api/v1/notifications/sendNotiVenue`, body)
      .post<any>(`${this.api}/notifications/sendNotiVenue`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
    
  } 
  peya_info({body}) {
    
    return this.http
      // .post<any>(`http://localhost:5001/v-go-app/us-central1/api/v1/peya/peya_information`, body)
      .post<any>(`${this.api}/peya/peya_information`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
    
  } 
  
 

  public getMyOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("orders", (ref) => ref.where("userId", "==", id))
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getMyPendingOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingOrders", (ref) =>
          ref
            .where("userId", "==", id)
            .where("status", "in", ["waitingPayment", "paymentRejected","expired"])
        )
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
 
  public getMyDeliveredOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("orders", (ref) =>
          ref
            .where("userId", "==", id)
            .where("status", "==", "delivered")
        )
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getMyExpiredOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingOrders", (ref) =>
          ref
            .where("userId", "==", id)
            .where("status", "in", ["waitingPayment", "paymentRejected","expired"])
            .where("expired", "==", true)
        )
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getMyOrdersOnGoing(id): Promise<any> {
    // console.log("entra a la fucnion de la api get my orderson going", id);
    
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("orders", (ref) =>
          ref
            .where("userId", "==", id)
            .where("status", "not-in", ["cancel", "delivered", "rejected"])
        )
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getMyOrdersOnGoingReal(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("orders", (ref) =>
          ref
            .where("userId", "==", id)
            .where("status", "not-in", ["cancel", "delivered", "rejected"])
        )
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getRecentPendingOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("pendingOrders", (ref) =>
          ref
            .where("userId", "==", id)
            .where("status", "in", ["waitingPayment", "paymentRejected"])
            .where("expired", "==", false)
        )
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  



  public getPendingOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("pendingOrders")
        .doc(id)
        .snapshotChanges()
        .pipe(
          map(data=> data.payload.data())
        )
        .subscribe(
          async (order: any) => {
            let data = order
            await data.vid.get().then(function (doc) {
              data.vid = doc.data();
              data.vid.id = doc.id;
            });
            await data.uid.get().then(function (doc) {
              data.uid = doc.data();
              data.uid.id = doc.id;
            });
           
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .snapshotChanges()
        .pipe(
          map(data=> data.payload.data())
        )
        .subscribe(
          async (order: any) => {
            let data = order
            await data.vid.get().then(function (doc) {
              data.vid = doc.data();
              data.vid.id = doc.id;
            });
            if (data && data.dId && !data.dId.cabify) {
              await data.dId.get().then(function (doc) {
                data.dId = doc.id;
                data.dId = doc.data();
              });
            }
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getDriverInfo(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .snapshotChanges()
        .subscribe(
          (data) => {
            // console.log(data);
            resolve(data.payload.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateOrderStatus(id, value): Promise<any> {
    const param = {
      status: value,
    };
    if (value === "cancel") {
      (param["cancelledAt"] = new Date()), (param["cancelledBy"] = "user");
    }
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .update(param)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public updateOrder(id, param): Promise<any> {
    // console.log("lo que llega a update order", id, param);
    
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .update(param)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public updatePendingOrder(id, param): Promise<any> {
    // console.log("lo que llega a update order", id, param);
    
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("pendingOrders")
        .doc(id)
        .update(param)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getDrivers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users", (ref) => ref.where("type", "==", "delivery"))
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public sendOrderToDriver(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("driverOrders")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public addReview(param): Promise<any> {
    param.vid = this.db.collection("venue").doc(param.vid);
    param.uid = this.db.collection("users").doc(param.uid);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("reviews")
        .doc(Math.random().toString())
        .set(param)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public addDriverReview(param): Promise<any> {
    param.uid = this.db.collection("users").doc(param.uid);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("driverreviews")
        .doc(Math.random().toString())
        .set(param)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public getFeedback(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.adb
            .collection("feedback", (ref) => ref.where("vid", "==", id))
            .get()
            .subscribe(
                async (review) => {
                    let data = review.docs.map((element) => {
                        let item: any = element.data();
                        item.id = element.id;
                        return item;
                    });
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            );
    });
}

  public updateVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .doc(informations.uid)
        .update(informations)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateProfile(uid, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("users")
        .doc(uid)
        .update(param)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getMyReviews(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("reviews", (ref) => ref.where("id", "==", id))
        .get()
        .subscribe(
          async (review) => {
            let data = review.docs.map((element) => {
              let item: any = element.data();
              item.id = element.id;
              if (item && item.vid) {
                item.vid.get().then(function (doc) {
                  item.vid = doc.data();
                });
              }
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenueTypes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venueTypes")
        .get()
        .subscribe(
          (venue: any) => {
            // resolve(venue.data());
            let data = venue.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            data.sort((a, b) => a.order - b.order);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }


  public getBanners(cityId?): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let address;
      if(!cityId){
        address = JSON.parse(localStorage.getItem('deliveryAddress'))
      }else{
        address = {
          city : cityId
        }

      }
      this.adb
    .collection("banners", (ref) => 
        ref.where("city", "==", address.city).where("status", "==", "active")
    )
    .get().subscribe(
          (venue: any) => {
            // resolve(venue.data());
            let data = venue.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getHomeBanners(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.adb
    .collection("config").doc("no_banners")
    .get().subscribe(
          (banners: any) => {
           
            resolve(banners.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  


  public getSubCategories(type): Promise<any> {
    // console.log(type);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("subcategories", (ref) => ref.where("type", "==", type))
        .get()
        .subscribe(
          (category: any) => {
            // resolve(category.data());
            let data = category.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  httpPost(url, body) {
    const header = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
      //.set('Authorization', `Bearer ${environment.mercadopago.accessToken}`)
    };
    const order = this.JSON_to_URLEncoded(body);
    // console.log(order);
    return this.http.post(url, order, header);
  }

  httpGet(url) {
    const header = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
      //.set('Authorization', `Bearer ${environment.mercadopago.accessToken}`)
    };

    return this.http.get(url, header);
  }

  JSON_to_URLEncoded(element, key?, list?) {
    let new_list = list || [];
    if (typeof element == "object") {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + "=" + encodeURIComponent(element));
    }
    return new_list.join("&");
  }

  createPreferencesMP({ body }) {
    // console.log("LLEGA A LA API", body);
    
    return this.http
      // .post<any>(`http://localhost:5001/v-go-app/us-central1/api/v1/payments/create_preference?type=wallet`, body)
      .post<any>(`${this.api}/payments/create_preference_new?type=wallet`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }
  sendNoti({ body }) {
    // console.log("LLEGA A LA API", body);
    
    return this.http
      .post<any>(`http://localhost:5001/v-go-app/us-central1/api/v1/notifications/sendNoti`, body)
      // .post<any>(`https://us-central1-v-go-app.cloudfunctions.net/api/v1/notifications/sendNoti`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }

  getFeedbackMP({
    payment_id,
    status,
    merchant_order_id,
  }: {
    payment_id: any;
    status?: any;
    merchant_order_id?: any;
  }) {
    return this.http
      .get<any>(
        `${this.api}/payments/feedback?payment_id=${payment_id}&status=${status}&merchant_order_id=${merchant_order_id}`
      )
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }

  createCardMP({ body, userId }) {
    return this.http
      .post(`${this.api}/payments/${userId}/create_card`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }
  async estimateDeliveryGeneral({ body }) {
      // console.log("llego hasta la fn de addnewparcelfront" , body);
      
    return this.http
      .post(`${this.api}/estimate/estimateDeliveryNew`, body)
      .pipe(
        map((data: any) => {
          console.log("respuesta de la fn api estimateDeliveryGeneral",data);
          return data;
        })
      );
  }
  addNewParcel({ body }) {
      // console.log("llego hasta la fn de addnewparcelfront",body);
      
    return this.http
      .post(`${this.api}/cabify/addNewParcel`, body)
      .pipe(
        map((data: any) => {
          // console.log("respuesta de la fn api addnewparcel",data);
          return data;
        })
      );
  }

  async estimatePeya({body}) {
      // console.log("llego hasta la fn de estimate delivery" );
      
    return this.http
      .post(`${this.api}/peya/estimateDelivery`, body)
      .pipe(
        map(( data: any) => {
          // console.log("respuesta de la fn api estimate delivery",data);
          return data;
        })
      );
  }
  async estimateDelivery({ body }) {
      // console.log("llego hasta la fn de estimate delivery" , body);
      
    return this.http
      .post(`${this.api}/cabify/estimateDelivery`, body)
      .pipe(
        map(( data: any) => {
          // console.log("respuesta de la fn api estimate delivery",data);
          return data;
        })
      );
  }
  async estimateDeliveries({ body }) {
      // console.log("llego hasta la fn de estimate delivery" , body);
      
    return this.http
      .post(`${this.api}/cabify/estimateDeliveries`, body)
      .pipe(
        map(( data: any) => {
          // console.log("respuesta de la fn api estimate delivery",data);
          return data;
        })
      );
  }
 
  

  createCustomerMP({ body, userId }) {
    return this.http
      .post(`${this.api}/payments/${userId}/create_customer`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }

  processPaymentMP({ body }) {
    return this.http
      .post<any>(`${this.api}/payments/process_payment`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }

  getCardsMP({ body, userId }: { body: any; userId: any }) {
    return this.http
      .post<any>(`${this.api}/payments/${userId}/cards`, body)
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        })
      );
  }

  public getLastId(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("lastId")
        .doc("orders")
        .get()
        .then(
          async (lastId) => {
            let data = lastId.data().id;
            // console.log("lastId", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public makeOrderId(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getLastId().then(
        async (lastId) => {
          // console.log("lastIdId", lastId);
          let newIdnum = lastId + 1;
          // console.log("newIdnum!", newIdnum);
          this.db
            .collection("lastId")
            .doc("orders")
            .set({
              id: newIdnum,
            })
            .then(() => {
              // console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
          let newId = JSON.stringify(newIdnum);
          resolve(newId);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public addDietType( param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("dietTypes")
        .add(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }
  public addNewOpinion(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("opinions")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getLastOpinionId(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("lastId")
        .doc("opinions")
        .get()
        .then(
          async (lastId) => {
            let data = lastId.data().id;
            // console.log("lastId", data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    // console.log(lat1, lon1, lat2, lon2);
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusKm * c;
    const distanceRound = Math.round(distance * 100) / 100;
    return distanceRound;
  }

  degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }
  
  public makeOpinionId(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getLastOpinionId().then(
        async (lastId) => {
          // console.log("lastIdId", lastId);
          let newIdnum = lastId + 1;
          // console.log("newIdnum!", newIdnum);
          this.db
            .collection("lastId")
            .doc("opinions")
            .set({
              id: newIdnum,
            })
            .then(() => {
              // console.log("Document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
          let newId = JSON.stringify(newIdnum);
          resolve(newId);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
// <<<<<<< HEAD
}
// =======

//   public searchProducts(param): Promise<any> {
//     // console.log("Este es el param que llega a la funcion: ", param);
    
//     return new Promise<any>((resolve, reject) => {
//       this.db.collectionGroup('all').where('name', 'array-contains',
//       param).get().then(docs => {
//         // console.log("Se ejecuta la funcion searchProducts en la api y esto llega ==> ", docs);
        
//         docs.forEach(doc => {
//           // console.log(doc.id, ' => ', doc.data());
//         })
//       })
//     });
//   }

// }
// >>>>>>> develop
