import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { UtilService } from 'src/app/services/util.service';
import { NavController, PopoverController } from '@ionic/angular';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import Swal from 'sweetalert2';
import { FirebaseConfigService } from 'src/app/services/firebase-config.service';
@Component({
  selector: 'app-choose-address',
  templateUrl: './choose-address.page.html',
  styleUrls: ['./choose-address.page.scss'],
})
export class ChooseAddressPage implements OnInit {
  id: any;
  idCategory: any;
  myaddress: any[] = [];
  from: any;
  selectedAddress: any;
  dummy = Array(10);
  venueLocation: any;
  deliveryRange: any = 6;
  deeplink: any = false;
  coupon:  any;
  constructor(
    private router: Router,
    private api: ApisService,
    private util: UtilService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private popoverController: PopoverController,
    private firebaseConfig: FirebaseConfigService
  ) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(data => {
      // console.log(data);
      if (data) {
        this.from = data.from || undefined;
        if (data.id) {
          this.idCategory = data.id
        }
        if (data.venueLat && data.venueLng) {
          this.venueLocation = {
            lat: Number(data.venueLat),
            lng: Number(data.venueLng)
          }
          // if(data.add_address){
          //   // console.log("no tengo direccion tengo que agregar")
            
          //   this.util.showCustomAlert("No tienes direccion", "Crea una nueva", "./assets/imgs/logopng-Negro.png", this.nothing.bind(this), this.addNew.bind(this))

          // }
          if (!this.myaddress.length) {
            this.getAddress()
          } else {
            this.calcAddressDistance();
          }
        }
        const coupon = localStorage.getItem('coupon')
        if(coupon){
          this.coupon = coupon
        }
        if(localStorage.getItem("selectedAddress")){
          this.selectedAddress = localStorage.getItem("selectedAddress")
        }
        if(data.deeplink){
          this.deeplink = data.deeplink
        }
      }
    });
  }

  calcAddressDistance() {
    if (this.venueLocation) {
      this.myaddress = this.myaddress.map(address => ({
        ...address,
        checked: address.checked? true : false,
        distance: this.distanceInKmBetweenEarthCoordinates(address.lat, address.lng, this.venueLocation.lat, this.venueLocation.lng),
      }));
      // console.log('my address', this.myaddress);

    } else if (localStorage.getItem('deliveryAddress')) {
      const currentAddress = JSON.parse(localStorage.getItem('deliveryAddress'))
      // console.log("ESTA ES LA DELIVERYADDRES DEL LOCALSTORAGE", currentAddress);
      if(currentAddress){
        this.myaddress = this.myaddress.map(address => ({
          ...address,
          distance: this.distanceInKmBetweenEarthCoordinates(address.lat, address.lng, currentAddress.lat, currentAddress.lng),
        }));
        // console.log('my address', this.myaddress);
      }
    }

    this.sortAddress()
  }

  sortAddress() {
    this.myaddress.sort((a, b) => a.distance - b.distance);
  }

  isDeliveryRange(address) {
    if (address.distance === undefined) return true
    return address.distance <= this.deliveryRange
  }

  getAddress() {
    if(this.id){
      this.api.getMyAddress(this.id).then((data) => {
      
        this.dummy = [];
        if (data && data.length) {
          
          data.forEach(element => {
            element.addressText = element.address.split(',')[0];
            element.icon = element.title == 'laburo' ? "build-outline" : element.title == 'casa'? "home-outline" : element.title == 'novi@'? "heart-circle-outline" : element.title == 'chong@'? "wine-outline" : element.title == 'BFF' ? "people-outline" : "location-outline"
          });
          this.myaddress = data;
          this.myaddress.filter(x => x.id === this.selectedAddress);
          this.calcAddressDistance();
        }else{
          this.myaddress = []
              // console.log("no tengo direccion tengo que agregar")
              this.util.showCustomAlertConfirmOnly("No tienes una dirección registrada", "Agrega tu dirección", "./assets/imgs/logopng-Negro.png", "Agregar dirección", this.addNew.bind(this))
  
        }
  

        // console.log('my address', data);
  
      }, error => {
        // console.log(error);
        this.dummy = [];
      }).catch(error => {
        // console.log(error);
        this.dummy = [];
      });
    }else{
      this.dummy = []
      this.myaddress = []
    }
    
  }

  ionViewWillEnter() {
    this.api.checkAuth().then(async (data: any) => {
      // console.log(data);
      if (data) {
        this.id = data.uid;
        this.getAddress();
        this.deliveryRange = await this.firebaseConfig.getDeliveryRange()
      }else{
        this.dummy = []
        this.myaddress = []
      }
    });
  }

  addNew() {
    this.router.navigate(['add-new-address']);
  }
  nothing() {

  }

  selectAddress(item) {
    // const selecte = this.myaddress.filter(x => x.id === this.selectedAddress);
    // const item = selecte[0];
    // // console.log(selecte);
    // console.log('item', item);
    const address = {
      address: item.address,
      city: item.city,
      lat: item.lat,
      lng: item.lng,
      id: item.id,
      landmark: item.landmark,
      house: item.house,
    };
    localStorage.setItem('deliveryAddress', JSON.stringify(address));
    localStorage.setItem("selectedAddress",item.id)
    localStorage.setItem('lastSignIn', Date.now().toString())
    // this.util.showToast('Address changed', 'success', 'bottom');
    // this.navCtrl.back();
    
    if (this.from === 'cart') {
      this.router.navigate(['checkout']);
      this.util.notificationSuccess('Ubicación seleccionada correctamente.');
    } else if (this.from === 'home') {
      const navData: NavigationExtras = {
        queryParams: {
          from: 'address',
        },
      };
      //this.router.navigate(['tabs/tab1']);
      this.util.publishAddress('data');
      this.util.notificationSuccess('Ubicación seleccionada correctamente.');
      this.router.navigate(['home'], navData);

    } else if (this.from === 'account') {
      this.util.publishAddress('data');
      this.util.notificationSuccess('Ubicación seleccionada correctamente.');
      this.router.navigate(['account']);
    } else if (this.from === 'category') {
      this.util.publishAddress('data');
      this.util.notificationSuccess('Ubicación seleccionada correctamente.');
      const navData: NavigationExtras = {
        queryParams: {
          id: this.idCategory,
        },
      };
      this.router.navigate(["category"], navData);

    } else if(this.deeplink || this.coupon){
     
      if(this.deeplink== "tracker"){
        const navData: NavigationExtras = {
          queryParams: {
            id: this.deeplink.id
          }
        };
  
      // Navigate to the '/tracker' route with query parameters
      this.deeplink = {
        info: "tracker",
        id: this.deeplink.id
      }
      this.router.navigate(['/tracker'], navData);
      }else if(this.deeplink.info == "category"){
        
        const navData: NavigationExtras = {
          queryParams: {
            id: this.deeplink.id,
          },
        };
        this.deeplink = {
          info: "category",
          id: this.deeplink.id
        }
        this.router.navigate(["category"], navData);
  
      }else if (this.deeplink.info =="coupon"){
        this.deeplink = {
          info: "coupon",
          id: this.deeplink.id
        }
  
        //Add a coupon to the user
        //If its logged, add it . 
        //If its not logged, wait for the user to log in and add the coupon.
        let user = localStorage.getItem("uid")
        if (!user) {
          const navData = {
            queryParams: {
              from: "deeplink",
              coupon: this.deeplink.id
            },
          };
          this.router.navigate(["login"], navData);
          this.util.errorToast(
            this.util.translate("In order to continue we need you to log in.")
          );
          return
        }else{
          this.api.addCoupon(user, {code: this.deeplink.id}).then(data => {
           
            this.router.navigate(["coupons"]);
          }).catch(error => {
            this.router.navigate(["coupons"]);
          
            // console.log(error);
            this.util.errorToast(error);
      
          });
        }
      }
      else if (this.coupon){
        this.deeplink = {
          info: "coupon",
          id: this.deeplink.id
        }

  
        //Add a coupon to the user
        //If its logged, add it . 
        //If its not logged, wait for the user to log in and add the coupon.
        let user = localStorage.getItem("uid")
        if (!user) {
          const navData = {
            queryParams: {
              from: "deeplink",
              coupon: this.deeplink.id
            },
          };
          this.router.navigate(["login"], navData);
          this.util.errorToast(
            this.util.translate("In order to continue we need you to log in.")
          );
          return
        }else{
          this.api.addCoupon(user, {code: this.coupon}).then(data => {
          this.util.notificationSuccess(`Se agregado el cupon ${this.coupon} con exito!`)
           
            this.router.navigate(["coupons"]);
            return
          }).catch(error => {
            this.router.navigate(["coupons"]);
          
            // console.log(error);
            this.util.errorToast(error);
      
          });
        }
      }
      
    }else {
      
      this.util.notificationSuccess('Ubicación seleccionada correctamente.');
      localStorage.setItem('deliveryAddress', JSON.stringify(address));
      localStorage.setItem("selectedAddress",item.id)

      // this.util.showToast('Address changed', 'success', 'bottom');
      // this.navCtrl.back();

      this.router.navigate(['home']);
    }
  }
  async deleteAddress(item){
    this.api.deleteAddress(item.uid, item.id).then(data => {
      // console.log(data);
      this.util.hide();
      this.getAddress();
    }).catch(error => {
      // console.log(error);
      this.util.hide();
    });
  }

  async openMenu(item, events) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: events,
      mode: 'ios',
    });
    popover.onDidDismiss().then(data => {
      // console.log(data.data);
      if (data && data.data) {
        if (data.data === 'edit') {
          const navData: NavigationExtras = {
            queryParams: {
              from: 'edit',
              data: JSON.stringify(item)
            }
          };
          this.router.navigate(['add-new-address'], navData);
        } else if (data.data === 'delete') {
          // console.log(item);
          Swal.fire({
            title: this.util.translate('Are you sure?'),
            text: this.util.translate('to delete this address'),
            icon: 'question',
            confirmButtonText: this.util.translate('Yes'),
            backdrop: false,
            background: 'white',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: this.util.translate('cancel')
          }).then(data => {
            // console.log(data);
            if (data && data.value) {
              this.util.show();
              this.api.deleteAddress(localStorage.getItem('uid'), item.id).then(data => {
                // console.log(data);
                this.util.hide();
                this.getAddress();
              }).catch(error => {
                // console.log(error);
                this.util.hide();
              });
            }
          });

        }
      }
    });
    await popover.present();
  }

  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    // console.log(lat1, lon1, lat2, lon2);
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusKm * c;
    return Math.round(distance * 100) / 100;
  }

 
}

