import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilService } from "src/app/services/util.service";
import { ApisService } from "src/app/services/apis.service";

@Component({
  selector: 'app-veggie-form',
  templateUrl: './veggie-form.component.html',
  styleUrls: ['./veggie-form.component.scss'],
})
export class VeggieFormComponent implements OnInit {

  vegan_venue: string;
  venue_name: string;
  diet_type: string;
  diet_info: string;
  user_answered: string;
  orderId: string;
  q: any = 0;
  answers: any = [];
  venue_questions: any = [
    'Sí, ya sabía', 'No, pero no me molesta', 'No, me hubiera gustado saberlo'
  ]
  questions_diet_type = {
    vegan: {
      title: 'Mientras se prepara tu pedido, ¡queremos conocerte un poco más!',
      subtitle: 'veganas',
      options: ['Pedí sol@', 'Si, todos somos vegan@s', 'No, también hay vegetarian@s', 'No, también hay omnívor@s']
    },
    vegetarian: {
      title: 'Mientras se prepara tu pedido, ¡queremos conocerte un poco más!',
      subtitle: 'vegetarian@s',
      options: ['Pedí sol@', 'Si, todos somos vegetarian@s', 'No, también hay omnívor@s', 'No, también hay vegan@s']
    },
    omnivore: {
      title: 'Mientras se prepara tu pedido, ¡queremos conocerte un poco más!',
      subtitle: 'omnívoras',
      options: ['Pedí sol@', 'Si, todos somos omnívor@s', 'No, también hay vegetarian@s', 'No, también hay vegan@s']
    },
    flexitarian: {
      title: 'Mientras se prepara tu pedido, ¡queremos conocerte un poco más!',
      subtitle: 'flexitarianos',
      options: ['Pedí sol@', 'Si, todos somos flexitarian@s', 'No, también hay vegetarian@s', 'No, también hay vegan@s']
    },
    pescetarian: {
      title: 'Mientras se prepara tu pedido, ¡queremos conocerte un poco más!',
      subtitle: 'pescetarianos',
      options: ['Pedí sol@', 'Si, todos somos pescetarian@s', 'No, también hay omnívor@s', 'No, también hay vegan@s']
    },
    other: {
      title: 'Mientras se prepara tu pedido, ¡queremos conocerte un poco más!',
      subtitle: `otro`,
      options: ['Pedí sol@', 'No, también hay omnívor@s' , 'No, también hay vegetarian@s', 'No, también hay vegan@s']
    },
  };
  vegetarian_food_options = {
    vegan: {
      title: '¿Qué te parecería que personas no veganas pudieran pedir opciones vegetarianas por V-Go?',
      subtitle: 'Esto no afectaría tu experiencia de compra',
      options: ['Me parece bien que las puedan pedir', 'Mientras no me afecte está todo bien', 'No me gustaría que las pidan por V-Go']
    },
    other: {
      title: `¿Cómo te sentirías si se pudiera pedir opciones vegetarianas por V-Go?`,
      subtitle: 'Podrías ocultarlas y estarían claramente señalizadas.',
      options: ['Me gustaría que se pudieran pedir', 'Me da igual mientras esté bien señalizado', 'No me gustaría que las ofrezcan en V-Go']
    },
  };
  
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private util: UtilService,
    private api: ApisService,

  ) { }

  ngOnInit() {
    this.venue_name = this.navParams.get('name');
    this.diet_type = this.navParams.get('diet_type');
    this.diet_info = this.navParams.get('diet_info');
    this.vegan_venue = this.navParams.get('vegan_venue');
    this.orderId = this.navParams.get('orderId');
    this.vegetarian_food_options.other.title = `¿Cómo te sentirías si se pudiera pedir opciones vegetarianas de ${this.venue_name} por V-Go?`
    this.questions_diet_type.other.subtitle = `"${this.diet_info}"`
  }
  
  closeModal() {
    this.modalController.dismiss({
      'answers': this.answers
    });
  }

  dismissModal() {
    this.modalController.dismiss({
      'answers': this.answers
    });
  }
  
 

  changeQuestion(value, i) {
    console.log('selecciono', value, i)
    if(this.q == 0){
      
      console.log(i)
      if(i == 0){
        this.answers.push('alone')
      }else if(i == 1){
        this.answers.push('friends_same_option')
      }else if(i == 2){
        this.answers.push('friends_different_option')
      }else{
        this.answers.push('friends_very_different_option')
      }
      if(this.vegan_venue || this.user_answered){
        console.log("el local es vegano entonces no se muestran mas preguntas", this.vegan_venue)
        console.log("el local ya respondio a este cuestionario entonces no se muestran mas preguntas", this.vegan_venue)
        this.q = 2
      }
      
    }else{
      this.answers.push(value)
    }
    console.log(this.q, ' + 1')
    this.q = this.q + 1;
    console.log("la q ahora es ==> ", this.q)
    if(this.q == 3){
      this.q = 4
      const param ={
        veggie_form:{
          vegan_venue : this.vegan_venue,
          createdAt: new Date(),
          userDietType: this.diet_type,
          answers: {
            q1: this.answers[0] ? this.answers[0] : '',
            q2: this.answers[1] ? this.answers[1] : '',
            q3: this.answers[2] ? this.answers[2] : ''
          }
        }
      }
      console.log("param", param)
      this.util.show()
      this.api.updateOrder(this.orderId, param ).then(response=>{
        console.log("listo a mostrar feedback.")
        const param2 = {
          veggie_form_answered : true
        }
        this.api.updateProfile(localStorage.getItem("uid"),param2).then(response=>{
          this.util.hide()
          this.closeModal()
          console.log("listo a mostrar feedback.")
        })
      })
      
    }
  }
}
